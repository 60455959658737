import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  SimpleGrid,
  FormLabel,
  Input,
  FormControl,
  Select,
  Divider,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  FormHelperText,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useForm, Controller, SubmitErrorHandler } from "react-hook-form";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import {
  FileElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import Modal, { ModalProps } from "components/atoms/Modal";
import { Form, InputElement as HookInput } from "components/atoms/HookForm";
import Dropzone from "components/atoms/Dropzone";
import { useToast } from "hooks/toast";
import { UserRoles } from "models/user";

function InventoryCreate() {
  const { project, user, role } = useAuth();
  const { control, handleSubmit, watch, setValue, register, formState } =
    useForm();
  const [addInventory, statusInventory, dataInventory] = Hooks.AddInventory();
  const [getTypes, , userTypes] = Hooks.UserTypeList();
  const toast = useToast();
  const history = useHistory();

  const [getCapacities, statCapacities, dataCapacities] = Hooks.CapacityList();

  const [getCampuses, statCampuses, dataCampuses] = Hooks.CampusList();
  const [getBuilds, statBuilds, dataBuilds] = Hooks.BuildList();
  const [getFloors, statFloors, dataFloors] = Hooks.FloorList();
  const [getRooms, statRooms, dataRooms] = Hooks.RoomList();
  const [getGroups, statGroups, dataGroups] = Hooks.GroupList();
  const [getBrands, statBrands, dataBrands] = Hooks.BrandList();
  const [getModels, statModels, dataModels] = Hooks.ModelList();

  const [onAddGroup, statusAddGroup] = Hooks.AddGroup();
  const [onAddBrand, statusAddBrand] = Hooks.AddBrand();
  const [onAddModel, statusAddModel] = Hooks.AddModel();

  const groupModal = useRef<ModalProps>(null);
  const brandModal = useRef<ModalProps>(null);
  const modelModal = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    if (!!!dataCampuses) {
      getCampuses(project.id);
    }

    getGroups(project.id);
    getCapacities();
    getTypes();
    register("InventoryPhoto");
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(watch("CampusID")))) {
      getBuilds(parseInt(watch("CampusID")));
      setValue("BuildID", undefined);
    }
  }, [watch("CampusID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("BuildID")))) {
      getFloors(parseInt(watch("BuildID")));
      setValue("FloorID", undefined);
    }
  }, [watch("BuildID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("FloorID")))) {
      getRooms(parseInt(watch("FloorID")));
      setValue("RoomID", undefined);
    }
  }, [watch("FloorID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("GroupID")))) {
      getBrands(parseInt(watch("GroupID")));
      setValue("ModelID", undefined);
    }
  }, [watch("GroupID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("BrandID")))) {
      getModels(parseInt(watch("BrandID")));
      setValue("ModelID", undefined);
    }
  }, [watch("BrandID")]);

  function onValid(values: any) {
    function checkField(value: string) {
      return !!value ? value : undefined;
    }
    addInventory(
      parseInt(values.UserTypeID),
      project.id,
      parseInt(values.CampusID),
      parseInt(values.BuildID),
      parseInt(values.FloorID),
      parseInt(values.RoomID),
      parseInt(values.GroupID),
      parseInt(values.BrandID),
      (isNaN(parseInt(values.ModelID)) ? 0 : parseInt(values.ModelID)) as number,
      (!!values.InventoryCapacityID
        ? parseInt(values.InventoryCapacityID)
        : 0) as unknown as number,
      values.Barcode,
      checkField(values.AssetNo),
      values.Name,
      checkField(values.SerialNumber),
      checkField(values.ProductionYear),
      checkField(values.InventoryPhoto),
      checkField(values.Explain),
      checkField(values.Unit),
      parseInt(values.Count),
      user.id,
      true
    );
  }

  function onInvalid() {
    toast({status: "error", title: "Zorunlu alanlar doldurulmalı!"});
  }


  useEffect(() => {
    if (!!dataInventory) {
      if (dataInventory.data.success) {
        toast({ status: "success", title: "Ekipman başarıyla eklendi!" });
        history.push(`/inventory`);
      } else {
        toast({
          status: "error",
          title: "Ekipman Eklenemedi",
          description: dataInventory.data.message,
        });
      }
    }
  }, [dataInventory]);


  

  return (
    <Main headerProps={{ title: "Ekipman Oluştur" }}>
      {formState.isSubmitted && !formState.isSubmitSuccessful && (
        <Card mb="3">
          <SimpleGrid columns={[2, 3]}>
            {Object.entries(formState.errors).map((item) => {
              return <Text color="red.500">* {item[1].message}</Text>;
            })}
          </SimpleGrid>
        </Card>
      )}
      <Card>
        <form onSubmit={handleSubmit(onValid, onInvalid)}>
          <Tabs isFitted>
            <TabList>
              <Tab>Lokasyon Bilgileri</Tab>
              <Tab>Teknik Bilgiler Ve Kullanıcı Tipi</Tab>
              <Tab>Genel Bilgiler</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <SelectElement
                    name="CampusID"
                    label="Tesis"
                    control={control}
                    isRequired
                    data={dataCampuses?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="BuildID"
                    label="Bina"
                    control={control}
                    isRequired
                    data={dataBuilds?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="FloorID"
                    label="Kat"
                    isRequired
                    control={control}
                    data={dataFloors?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="RoomID"
                    label="Oda"
                    isRequired
                    control={control}
                    data={dataRooms?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1,2]} gap="5">
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="GroupID"
                      label="Grup"
                      isRequired
                      control={control}
                      data={dataGroups?.data.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        groupModal.current?.open();
                      }}
                    />
                  </Flex>
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="BrandID"
                      label="Marka"
                      control={control}
                      data={dataBrands?.data.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        if (watch("GroupID") != 0) {
                          brandModal.current?.open();
                        } else {
                          toast({ status: "error", title: "Grup seçiniz!" });
                        }
                      }}
                    />
                  </Flex>
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="ModelID"
                      label="Model"
                      control={control}
                      data={dataModels?.data.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        if (watch("BrandID") != 0) {
                          modelModal.current?.open();
                        } else {
                          toast({ status: "error", title: "Marka seçiniz!" });
                        }
                      }}
                    />
                  </Flex>
                </SimpleGrid>
                <Divider my="5" />
                <SimpleGrid columns={[1,2]} gap="5">
                  <SelectElement
                    name="UserTypeID"
                    label="Kullanıcı Tipi"
                    isRequired
                    control={control}
                    data={userTypes?.data.data.filter((item) =>
                      role === UserRoles.admin
                        ? true
                        : user.userTypes
                            .map((_type: any) => _type.id)
                            .includes(item.id)
                    ).map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <InputElement
                    name="Name"
                    label="Ekipman Adı"
                    isRequired
                    control={control}
                  />
                  <InputElement
                    name="SerialNumber"
                    label="Seri No"
                    control={control}
                  />
                  <InputElement
                    name="Barcode"
                    label="Barkod"
                    isRequired
                    control={control}
                  />
                  <InputElement
                    name="Explain"
                    label="Açıklama"
                    control={control}
                  />
                  <SelectElement
                    name="InventoryCapacityID"
                    label="Kapasite"
                    control={control}
                    data={(dataCapacities?.data.data as any[])?.map(
                      (capacity) => ({
                        label: capacity.name,
                        value: capacity.id,
                      })
                    )}
                  />
                  <InputElement
                    name="Unit"
                    label="Kapasite Değeri"
                    control={control}
                  />
                  <InputElement
                    name="AssetNo"
                    label="Asset No"
                    control={control}
                  />
                  <InputElement
                    name="ProductionYear"
                    label="Üretim Yılı"
                    control={control}
                  />
                  <InputElement
                    name="Count"
                    label="Adet"
                    defaultValue="1"
                    control={control}
                  />
                  <FormControl>
                    <FormLabel>Ekipman Fotoğrafı</FormLabel>
                    <Dropzone
                      onDrop={(files) => setValue("InventoryPhoto", files[0])}
                    />
                  </FormControl>
                </SimpleGrid>
                <Flex justifyContent="flex-end" mt="5">
                  <Button colorScheme="teal" type="submit">
                    Oluştur
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </Card>
      <Modal title="Grup Ekle" ref={groupModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddGroup({ name: name, projectID: project.id });
            toast({ status: "success", title: "Grup başarıyla eklendi!" });
            getGroups(project.id);
            groupModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Grup Ekle
          </Button>
        </Form>
      </Modal>
      <Modal title="Marka Ekle" ref={brandModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddBrand({
              name: name,
              groupID: parseInt(watch("GroupID")),
            });
            toast({ status: "success", title: "Marka başarıyla eklendi!" });
            getBrands(parseInt(watch("GroupID")));
            brandModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Marka Ekle
          </Button>
        </Form>
      </Modal>
      <Modal title="Model Ekle" ref={modelModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddModel({
              name: name,
              brandID: parseInt(watch("BrandID")),
            });
            toast({ status: "success", title: "Model başarıyla eklendi!" });
            getModels(parseInt(watch("BrandID")));
            modelModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Model Ekle
          </Button>
        </Form>
      </Modal>
    </Main>
  );
}

export default InventoryCreate;
