import React, {
  FC,
  ReactElement,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Card, ModalProps, Modal } from "components/atoms";
import { Hooks } from "services";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import { useAuth } from "hooks/redux-hooks";
import TimelineHeader from "./TimelineHeader";
import TimelineItem, { CounterStatus } from "./Item";
import { IoFilter, IoInformation, IoSearch, IoTrash } from "react-icons/io5";
import { TimelineCounter as ITimelineItem } from "./Item";

type TimelineCounterProps = {
  renderChangeDropdown: () => ReactElement;
  hidden?: boolean;
  hiddenFuture?: boolean;
  selectedCampus?: number;
  onChangeCount?: (count: number) => void;
};

const TimelineCounter: FC<TimelineCounterProps> = (props) => {
  const [get, status, data] = Hooks.CounterTimeline();
  const [getDesc, statDesc, dataDesc] = Hooks.CounterTimelineStatus();
  const [getData, statusData, dataData] = Hooks.CounterTimelineStatus();
  const [cancel] = Hooks.CancelCounter();
  const filterModalRef = useRef<ModalProps>(null);
  const { project } = useAuth();
  const [filterA, setFilterA] = useState({
    search: "",
  });

  const [filter, setFilter] = useState({
    types: [] as number[],
    status: [] as string[],
    period: [] as number[],
  });

  const [currentFilter, setCurrentFilter] = useState({
    types: [] as number[],
    status: [] as string[],
    period: [] as number[],
  });

  const searchDisc = useDisclosure({
    onClose: () => setFilterA((prev) => ({ ...prev, search: "" })),
  });

  useLayoutEffect(() => {
    get(project.id);
    getDesc();
  }, []);

  function handleFilter() {
    setCurrentFilter(filter);
    filterModalRef.current?.close();
  }

  function clearFilter() {
    setCurrentFilter({
      types: [] as number[],
      status: [] as string[],
      period: [] as number[],
    });
    setFilter({
      types: [] as number[],
      status: [] as string[],
      period: [] as number[],
    });
    filterModalRef.current?.close();
  }

  function onFilter(item: ITimelineItem) {
    const filterArr = [];

    if (filter.status.length !== 0) {
      filterArr.push(filter.status.includes(item.statusColor));
    }
    if (filter.types.length !== 0) {
      filterArr.push(filter.types.includes(item.counterTypeId));
    }
    if (filter.period.length !== 0) {
      filterArr.push(filter.period.includes(item.periodId));
    }

    if (props.hidden) {
      if ([CounterStatus.done].includes(item.statusCode)) {
        filterArr.push(false);
      }
    }

    if (props.hiddenFuture) {
      if (
        [CounterStatus.late, CounterStatus.wait, CounterStatus.wait].includes(
          item.statusCode
        )
      ) {
        filterArr.push(false);
      }
    }

    return (
      !filterArr.includes(false) &&
      item.location.toLowerCase().includes(filterA.search.toLowerCase())
    );
  }

  return (
    <>
      <Card w="100%" minH="calc(100vh - 200px)">
        <Flex justifyContent="space-between" mb="2">
          {props.renderChangeDropdown()}
          <Flex mt="2">
            <Tooltip label="Filtre" aria-label="A tooltip">
              <IconButton
                size="sm"
                mr="1"
                aria-label="Filtre"
                icon={<IoFilter />}
                onClick={() => {
                  filterModalRef.current?.open();
                }}
              />
            </Tooltip>
            <Tooltip label="Ara" aria-label="Search">
              <IconButton
                mr="1"
                onClick={searchDisc.onToggle}
                size="sm"
                aria-label="search"
                icon={<IoSearch />}
              />
            </Tooltip>
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <Box>
                  <Tooltip
                    label="Renk Açıklamaları"
                    aria-label="Colors tooltip"
                  >
                    <IconButton
                      size="sm"
                      aria-label="colors"
                      icon={<IoInformation />}
                    />
                  </Tooltip>
                </Box>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Doküman Renk Açıklamaları</PopoverHeader>
                <PopoverBody>
                  {dataDesc?.data?.data.map((color: any, index: number) => (
                    <Flex alignItems="center" mb="1">
                      <Box
                        w="20px"
                        h="20px"
                        mr="2"
                        bgColor={color.statusColor}
                        rounded="sm"
                      ></Box>
                      <Text>{color.statusDesc}</Text>
                    </Flex>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
        <Box>
          <Collapse in={searchDisc.isOpen} animateOpacity>
            <Box>
              <Input
                value={filterA.search}
                onChange={(e) =>
                  setFilter((prev) => ({ ...prev, search: e.target.value }))
                }
                disabled={!searchDisc.isOpen}
                size="sm"
                placeholder="Tüketim Bölgesine Göre Ara..."
              />
            </Box>
          </Collapse>
        </Box>
        <Text mt="4" mb="2">
          Sayaç İşlemleri
        </Text>
        {status === "fulfilled" || status === "rejected" ? (
          <Box>
            <TimelineHeader />
            <PerfectScrollbar maxHeight={"calc(100vh - 250px)"}>
              {data?.data.data
                .filter(onFilter)
                .map((item: any, index: number) => (
                  <TimelineItem
                    data={item}
                    key={index}
                    onCancel={async () => {
                      await cancel({ counterTaskId: item.counterTaskID });
                      get(project.id);
                      getDesc();
                    }}
                  />
                ))}
            </PerfectScrollbar>
          </Box>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </Card>
      <Modal ref={filterModalRef} noForm title="Filtre" onClose={() => {}}>
        <Box color="gray.600">
          <Heading color="gray.600" mt="-3" fontSize="md">
            Doküman Durumu
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataDesc?.data?.data.map((_color: any) => (
              <Flex alignItems="center" borderWidth="1px" px="3" py="1">
                <Checkbox
                  borderColor={_color.statusColor}
                  defaultChecked={filter.status.includes(_color.statusColor)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.status.includes(_color.statusColor)) {
                        setFilter((_state) => ({
                          ..._state,
                          status: [..._state.status, _color.statusColor],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        status: _state.status.filter(
                          (_statu) => _statu !== _color.statusColor
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_color.statusDesc}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading color="gray.600" mt="3" fontSize="md">
            Sayaç Türü
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            <Flex alignItems="center" borderWidth="1px" px="3" py="1">
              <Checkbox
                defaultChecked={filter.types.includes(1)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    if (!filter.types.includes(1)) {
                      setFilter((_state) => ({
                        ..._state,
                        types: [..._state.types, 1],
                      }));
                    }
                  } else {
                    setFilter((_state) => ({
                      ..._state,
                      types: _state.types.filter((_statu) => _statu !== 1),
                    }));
                  }
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  Elektrik
                </Text>
              </Checkbox>
            </Flex>
            <Flex alignItems="center" borderWidth="1px" px="3" py="1">
              <Checkbox
                defaultChecked={filter.types.includes(2)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    if (!filter.types.includes(2)) {
                      setFilter((_state) => ({
                        ..._state,
                        types: [..._state.types, 2],
                      }));
                    }
                  } else {
                    setFilter((_state) => ({
                      ..._state,
                      types: _state.types.filter((_statu) => _statu !== 2),
                    }));
                  }
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  Su
                </Text>
              </Checkbox>
            </Flex>
            <Flex alignItems="center" borderWidth="1px" px="3" py="1">
              <Checkbox
                defaultChecked={filter.types.includes(3)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    if (!filter.types.includes(3)) {
                      setFilter((_state) => ({
                        ..._state,
                        types: [..._state.types, 3],
                      }));
                    }
                  } else {
                    setFilter((_state) => ({
                      ..._state,
                      types: _state.types.filter((_statu) => _statu !== 3),
                    }));
                  }
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  Doğalgaz
                </Text>
              </Checkbox>
            </Flex>
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="10">
            <Button
              leftIcon={<IoTrash />}
              onClick={clearFilter}
              mr="2"
              colorScheme="red"
              variant="outline"
              size="sm"
            >
              Temizle
            </Button>
            <Button
              leftIcon={<IoFilter />}
              colorScheme="blue"
              minW="200px"
              onClick={handleFilter}
              size="sm"
            >
              Filtre
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};

export default TimelineCounter;
