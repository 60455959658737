import React, { FC } from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";
import Confirm from "components/atoms/Confirm";

export interface TimelineCounter {
  counterID: number;
  counterTypeId: number;
  counterTaskID: number;
  counterTypeName: string;
  barcode: string;
  location: string;
  isTRequired: boolean;
  isT1Required: boolean;
  isT2Required: boolean;
  isT3Required: boolean;
  isKapasitifRequired: boolean;
  isReaktifRequired: boolean;
  isDemantRequired: boolean;
  periodId: number;
  periodName: string;
  startDate: string;
  beforeDate: string;
  endDate: string;
  t?: any;
  t1?: any;
  t2?: any;
  t3?: any;
  kapasitif?: any;
  reaktif?: any;
  demant?: any;
  value?: any;
  description?: any;
  photoPath?: any;
  controlledUserID?: any;
  controlledUserName?: any;
  isCompleted: boolean;
  completedDate?: any;
  statusCode: number;
  statusColor: string;
  statusDesc: string;
  multiplierValue: number;
  consumptionDifferencePercentage: number;
}

export enum CounterStatus {
  done = 1,
  ready = 2,
  wait = 3,
  late = 4,
  lateTime = 5,
}

type TimelineItemProps = {
  data: TimelineCounter;
  onCancel: () => void;
};

const TimelineItem: FC<TimelineItemProps> = (props) => {
  const history = useHistory();
  return (
    <Flex
      fontSize="xs"
      border="1px"
      mb="0.5"
      py="0.5"
      borderColor={useColorModeValue("gray.200", "gray.700")}
      _odd={{
        bgColor: useColorModeValue("gray.100", "gray.800"),
      }}
    >
      <Center flexDir="column" flex="1">
        <Text fontSize="small" lineHeight="1">
          {Moment(props.data.endDate).format("DD")}
        </Text>
        <Text fontSize="small" lineHeight="1">
          {Moment(props.data.endDate).format("MMMM")}
        </Text>
      </Center>
      <Flex alignItems="center" flex="1">
        <Box
          rounded="full"
          mr="1"
          w="10px"
          h="10px"
          bgColor={props.data.statusColor}
        />
        <Text>{props.data.barcode}</Text>
      </Flex>
      <Flex flex="1" alignItems="center">
        <Text>{props.data.location}</Text>
      </Flex>
      <Flex flex="1" alignItems="center">
        <Text>{(props.data as any).counterTypeName}</Text>
      </Flex>
      <Flex flex="1" alignItems="center">
        <Text>{props.data.periodName}</Text>
      </Flex>
      <Flex flex="1" alignItems="center">
        <Text>{Moment(props.data.endDate).format("DD.MM.YYYY")}</Text>
      </Flex>
      <Flex
        alignItems="center"
        w="100px"
        mx="3"
        minW="170px"
        justifyContent="flex-end"
      >
        {[CounterStatus.done].includes(props.data.statusCode) && (
          <>
            <Badge colorScheme="green">Kontrol Yapıldı</Badge>{" "}
            <Confirm
              onConfirm={async () => {
                props.onCancel();
              }}
              colorScheme="orange"
              confirmText="Geri Almayı Onayla"
              text="Tamamlanan kontrol geri alınacak. Bu işlemi onaylıyor musunuz?"
            >
              {({ onOpen }) => (
                <Button size="xs" ml="2" onClick={onOpen}>
                  Geri Al
                </Button>
              )}
            </Confirm>
          </>
        )}
        {[CounterStatus.wait].includes(props.data.statusCode) && (
          <Badge colorScheme="orange">Bekleniyor</Badge>
        )}
        {props.data.statusCode === CounterStatus.late &&
          props.data.isCompleted && (
            <Badge colorScheme="green">Zamanı Dışında Gerçekleşti</Badge>
          )}
        {props.data.statusCode === CounterStatus.late &&
          !props.data.isCompleted && (
            <Button
              size="xs"
              colorScheme="teal"
              onClick={() => {
                history.push(
                  "/counter/do-maintenance/" +
                    props.data.counterID +
                    "/" +
                    props.data.counterTaskID,
                  {
                    item: props.data,
                  }
                );
              }}
            >
              İşlem Yap
            </Button>
          )}
        {[CounterStatus.ready].includes(props.data.statusCode) && (
          <Button
            size="xs"
            colorScheme="teal"
            onClick={() => {
              history.push(
                "/counter/do-maintenance/" +
                  props.data.counterID +
                  "/" +
                  props.data.counterTaskID,
                {
                  item: props.data,
                }
              );
            }}
          >
            İşlem Yap
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default TimelineItem;
