import React, { Fragment, useEffect } from "react";
import ModalAtom from "components/atoms/Modal";
import { useForm } from "react-hook-form";
import { useAuth } from "hooks/redux-hooks";
import { UserRoles } from "models/user";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Input,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { Hooks } from "services";
import Moment from "moment";

interface Props {
  modalRef: any;
  dataUL: any;
  dataQF: any;
  dataP: any;
  task: any;
  onSuccess: () => void;
}

const UpdateModal = (props: Props) => {
  const { register, handleSubmit, formState, watch } = useForm();
  const { role } = useAuth();
  const [updateAT, statAT, dataAT] = Hooks.UpdateTask();

  function onValid(values: any) {
    updateAT({
      id: props.task.id,
      isActive: props.task.isActive,
      beforeDay: props.task.beforeDay,
      controlID: props.task.controlID,
      controlPeriodID: props.task.controlPeriodID,
      controlQuestionGroupID: parseInt(values.controlQuestionGroupID),
      userID: parseInt(values.userID),
      explain: values.explain,
      startDate: props.task.startDate
    });
  }

  useEffect(() => {
    if (statAT === "fulfilled") {
      props.onSuccess();
    }
  }, [statAT]);

  return (
    <ModalAtom
      ref={props.modalRef}
      onConfirmText="Düzenle"
      title="Görev Düzenle"
      onClose={() => {}}
      isConfirmSpinner={statAT === "pending"}
      onConfirm={() => {
        handleSubmit(onValid)();
      }}
    >
      <FormControl isInvalid={formState.errors["userID"]}>
        <FormLabel>
          Kullanıcı{" "}
          <Text d="inline" color="red.500">
            *
          </Text>
        </FormLabel>
        <Select
          defaultValue={props?.task?.userID}
          {...register("userID", {
            required: {
              value: true,
              message: "Bu Alanın Doldurulması Zorunludur!",
            },
            validate: (value: any) => value !== "0",
          })}
        >
          <option value="0">Seç</option>
          {!!props.dataUL?.data.data &&
            props.dataUL.data.data
              .filter((item: any) =>
                role === UserRoles.admin ? true : item?.userRoleID >= role
              )
              .map((user: any, index: number) => (
                <option key={index} value={user.id}>
                  {user.username}
                </option>
              ))}
          <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
        </Select>
      </FormControl>
      <FormControl
        mt="2"
        isInvalid={formState.errors["controlQuestionGroupID"]}
      >
        <FormLabel>
          Soru Grubu{" "}
          <Text d="inline" color="red.500">
            *
          </Text>
        </FormLabel>
        <Select
          defaultValue={props?.task?.controlQuestionGroupID}
          {...register("controlQuestionGroupID", {
            required: {
              value: true,
              message: "Bu Alanın Doldurulması Zorunludur!",
            },
            validate: (value: any) => value !== "0",
          })}
        >
          <option value="0">Seç</option>
          {!!props.dataQF?.data.data &&
            props.dataQF.data.data.map((item: any, index: number) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
        </Select>
        <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
      </FormControl>
      <FormControl mt="2">
        <FormLabel>Açıklama</FormLabel>
        <Textarea
          defaultValue={props?.task?.explain}
          {...register("explain", {})}
        />
      </FormControl>
    </ModalAtom>
  );
};

export default UpdateModal;
