import React, {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Center,
  Flex,
  Spinner,
  Text,
  Badge,
  Button,
  Divider,
  Heading,
  IconButton,
  Input,
  useDisclosure,
  Checkbox,
  SimpleGrid,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Card, Modal, ModalProps } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import { TimelineDemand as ITimelineDemand, TimelineItemDetail } from "models";
import Dropzone from "components/atoms/Dropzone";
import Moment from "moment";
import {
  IoAlbumsOutline,
  IoBackspace,
  IoCheckmark,
  IoDocumentAttachOutline,
  IoEllipsisHorizontal,
  IoFileTray,
  IoFilter,
  IoRemove,
  IoSearch,
  IoTrash,
} from "react-icons/io5";
import { Tooltip } from "@chakra-ui/react";
import user, { UserRoles } from "models/user";
import Confirm from "components/atoms/Confirm";

interface TimelineDemandProps {
  renderChangeDropdown?: () => ReactElement;
  hidden: boolean;
  hiddenFuture: boolean;
  selectedCampus: number;
  onChangeCount: (count: number) => void;
}

const TimelineDemand: FC<TimelineDemandProps> = (props) => {
  const { project, user } = useAuth();
  const [getTimeline, statTimeline, dataTimeline] = Hooks.DemandTimeline();
  const [getTypes, statType, dataTypes] = Hooks.UserTypeList();
  const [getGroups, statGroups, dataGroups] = Hooks.DemandGroupList();
  const searchDisc = useDisclosure();
  const [filterData, setFilterData] = useState({ name: "", isDone: false });
  const filterModalRef = useRef<ModalProps>(null);
  const [iStat, setIStat] = useState<boolean | null>(null);

  const [filter, setFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
    groups: [] as number[],
    onlyMe: true as boolean,
  });

  const [currentFilter, setCurrentFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
    groups: [] as number[],
    onlyMe: true as boolean,
  });

  function _get() {
    getTimeline(project.id);
  }

  useLayoutEffect(() => {
    _get();
    getTypes(project.id);
    getGroups(project.id);
  }, []);

  function handleFilter() {
    setCurrentFilter(filter);
    filterModalRef.current?.close();
  }

  function clearFilter() {
    setCurrentFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
      groups: [] as number[],
      onlyMe: true as boolean,
    });
    setFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
      groups: [] as number[],
      onlyMe: true as boolean,
    });
    filterModalRef.current?.close();
  }

  function onFilter(item: any) {
    let filterArr = [];

    const isRequiredDoc = item.documents
      .map((_item: any) => _item.isCompleted)
      .includes(false);

    if (currentFilter.status.length !== 0) {
      filterArr.push(currentFilter.status.includes(item.statusColor));
    }

    if (currentFilter.groups.length !== 0) {
      filterArr.push(currentFilter.groups.includes(item.demandGroupID));
    }

    if (currentFilter.userTypes.length !== 0) {
      filterArr.push(
        currentFilter.userTypes.includes((item as any).userTypeID)
      );
    }

    if (props.hidden) {
      filterArr.push(isRequiredDoc);
    }

    if (props.selectedCampus !== 0) {
      filterArr.push(item.campusID === props.selectedCampus);
    }

    if (!!currentFilter.onlyMe) {
      const indexOf = item.documents.findIndex(
        (doc: any) => doc.assignUserID === user.id
      );
     
      filterArr.push(indexOf !== -1);
    }

    if (iStat !== null) {
      filterArr.push(isRequiredDoc !== iStat);
    }

    return !filterArr.includes(false);
  }

  function onFilterData(item: ITimelineDemand) {
    const isRequiredDoc = item.documents
      .map((item) => item.isCompleted)
      .includes(false);

    const _filters = [
      !!filterData.name
        ? item.demandName.toLowerCase().includes(filterData.name.toLowerCase())
        : true,
      filterData.isDone ? isRequiredDoc : true,
    ];
    return !_filters.includes(false);
  }

  useEffect(() => {
    if (dataTimeline?.data.data) {
      const count = dataTimeline?.data.data
        .filter(onFilterData)
        .filter(onFilter).length;
      props.onChangeCount(count);
    }
  }, [filter, props.hiddenFuture, props.hidden, dataTimeline, currentFilter]);

  return (
    <Card w="100%" minH="calc(100vh - 200px)">
      <Flex mb="2" justifyContent="space-between">
        <Box>{props.renderChangeDropdown && props.renderChangeDropdown()}</Box>
        <Flex alignItems="center">
          <Button
            leftIcon={<IoFilter />}
            onClick={() => {
              filterModalRef.current?.open();
            }}
            size="sm"
            mr="1"
          >
            Filtre
          </Button>
          <Tooltip
            label="Sadece Doküman Gereken Talepleri Göster!"
            aria-label="A tooltip"
          >
            <IconButton
              aria-label="isDone"
              variant={filterData.isDone ? "solid" : "outline"}
              icon={<IoDocumentAttachOutline />}
              size="sm"
              mr="1"
              onClick={() => {
                setFilterData((_datas) => ({
                  ..._datas,
                  isDone: !filterData.isDone,
                }));
              }}
            />
          </Tooltip>

          <IconButton
            aria-label="Search"
            variant={searchDisc.isOpen ? "solid" : "outline"}
            icon={<IoSearch />}
            size="sm"
            onClick={searchDisc.onToggle}
          />
        </Flex>
      </Flex>
      {searchDisc.isOpen && (
        <Box mb="2">
          <Input
            type="text"
            size="sm"
            placeholder="Ara..."
            value={filterData.name}
            onChange={(e) =>
              setFilterData({ ...filterData, name: e.currentTarget?.value })
            }
          />
        </Box>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="semibold" mb="1" mt="2">
          Talep İşlemleri
        </Text>
        <Flex>
          <Tooltip label="Gerçekleşen">
            <IconButton
              size="xs"
              colorScheme="green"
              variant={iStat === true ? "solid" : "outline"}
              aria-label="complete"
              onClick={() => {
                setIStat(true);
              }}
              icon={<IoCheckmark />}
            />
          </Tooltip>
          <Tooltip label="Hepsi">
            <IconButton
              mx="1"
              size="xs"
              aria-label="complete"
              variant={iStat === null ? "solid" : "outline"}
              onClick={() => {
                setIStat(null);
              }}
              icon={<IoAlbumsOutline />}
            />
          </Tooltip>
          <Tooltip label="Gerçekleşmeyen">
            <IconButton
              size="xs"
              aria-label="complete"
              variant={iStat === false ? "solid" : "outline"}
              onClick={() => {
                setIStat(false);
              }}
              colorScheme="red"
              icon={<IoRemove />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Box h="100%">
        {statTimeline !== "fulfilled" ? (
          <Center h="400px">
            <Spinner />
          </Center>
        ) : (
          <Timeline
            data={dataTimeline?.data.data.filter(onFilterData).filter(onFilter)}
            onlyMe={currentFilter.onlyMe}
            onNeedReload={() => {
              _get();
            }}
          />
        )}
      </Box>
      <Modal
        ref={filterModalRef}
        noForm
        title="Filtre"
        onClose={() => {}}
        onOpen={() => {
          setFilter(currentFilter);
        }}
      >
        <Box color="gray.600">
          <Heading color="gray.600" mt="3" fontSize="md">
            Kullanıcı Tipi
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataTypes?.data.data
              .filter((_period: any) =>
                user.userTypes.map((_: any) => _.id).includes(_period.id)
              )
              .map((_period: any) => (
                <Flex alignItems="center" borderWidth="1px" px="3" py="2">
                  <Checkbox
                    defaultChecked={filter.userTypes.includes(_period.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        if (!filter.userTypes.includes(_period.id)) {
                          setFilter((_state) => ({
                            ..._state,
                            userTypes: [..._state.userTypes, _period.id],
                          }));
                        }
                      } else {
                        setFilter((_state) => ({
                          ..._state,
                          userTypes: _state.userTypes.filter(
                            (_periodId) => _periodId !== _period.id
                          ),
                        }));
                      }
                    }}
                  >
                    <Text fontSize="xs" lineHeight="4">
                      {_period.name}
                    </Text>
                  </Checkbox>
                </Flex>
              ))}
          </SimpleGrid>
          <Heading color="gray.600" mt="3" fontSize="md">
            Grup
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataGroups?.data.data.map((_period: any) => (
              <Flex alignItems="center" borderWidth="1px" px="3" py="2">
                <Checkbox
                  defaultChecked={filter.groups.includes(_period.id)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.groups.includes(_period.id)) {
                        setFilter((_state) => ({
                          ..._state,
                          groups: [..._state.groups, _period.id],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        groups: _state.groups.filter(
                          (_periodId) => _periodId !== _period.id
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_period.name}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading color="gray.600" mt="3" fontSize="md">
            Doküman Atanan Kullanıcı
          </Heading>
          <Divider my="2" />
          <SimpleGrid columns={[2, 3]}>
            <Flex alignItems="center" borderWidth="1px" px="3" py="2">
              <Checkbox
                defaultChecked={filter.onlyMe}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, onlyMe: !prev.onlyMe }));
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  {"Sadece Ben"}
                </Text>
              </Checkbox>
            </Flex>
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="10">
            <Button
              leftIcon={<IoTrash />}
              onClick={clearFilter}
              mr="2"
              colorScheme="red"
              variant="outline"
              size="sm"
            >
              Temizle
            </Button>
            <Button
              leftIcon={<IoFilter />}
              colorScheme="blue"
              minW="200px"
              onClick={handleFilter}
              size="sm"
            >
              Filtre
            </Button>
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
};

interface TimelineProps {
  data: ITimelineDemand[];
  onNeedReload: () => void;
  onlyMe?: boolean;
}
const Timeline = (props: TimelineProps) => {
  if (props.data.length === 0) {
    return (
      <Center minH="300px">
        <Text>Talep İşlemi Bulunamadı</Text>
      </Center>
    );
  }
  return (
    <PerfectScrollbar maxHeight={"calc(100vh - 250px)"}>
      <TimelineItemTitle />
      {props.data.map((item, index) => (
        <TimelineItem
          onlyMe={props.onlyMe}
          data={item}
          key={index}
          onNeedReload={() => {
            props.onNeedReload();
          }}
        />
      ))}
    </PerfectScrollbar>
  );
};

interface TimelineItemProps {
  data: ITimelineDemand;
  onNeedReload: () => void;
  onlyMe?: boolean;
}

const TimelineItem = (props: TimelineItemProps) => {
  const { role, user } = useAuth();
  const modalRef = useRef<ModalProps>(null);
  const [loading, setLoading] = useState<any>(false);

  const isRequiredDoc = props.data.documents
    .map((item) => item.isCompleted)
    .includes(false);

  return (
    <Fragment>
      <Flex
        mb="2"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        p="2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center" flex="1" ml="2">
          <Box flex="1" flexBasis="25px">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data?.demandID}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.demandName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.demandGroupName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.campusName}
            </Text>
          </Box>
          {/* <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Bina
            </Text>
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.buildName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Kat
            </Text>
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.floorName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Oda
            </Text>
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.roomName}
            </Text>
          </Box> */}
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.userTypeName}
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {Moment(props.data.demandCreateDate).format("DD.MM.YYYY")}
            </Text>
          </Box>
        </Flex>

        <Flex minW="290px" justifyContent="flex-end" alignItems="center">
          {isRequiredDoc && (
            <Badge
              colorScheme="orange"
              ml="2"
              py="1"
              size="sm"
              textAlign="center"
            >
              Doküman Gerekli
            </Badge>
          )}
          {!isRequiredDoc && (
            <Badge
              colorScheme="green"
              ml="2"
              py="1"
              size="sm"
              textAlign="center"
            >
              Tamamlandı
            </Badge>
          )}
          {isRequiredDoc && (
            <Button
              size="xs"
              ml="2"
              colorScheme="blue"
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              Doküman Yükle
            </Button>
          )}
          {!isRequiredDoc && (
            <>
              <Menu placement="bottom-end">
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      ml="2"
                      isActive={isOpen}
                      as={IconButton}
                      size="xs"
                      icon={<IoEllipsisHorizontal />}
                    />
                    <MenuList>
                      <MenuItem
                        icon={<IoFileTray />}
                        onClick={() => {
                          modalRef.current?.open();
                        }}
                      >
                        <Text fontSize="xs">Dokümanları Gör</Text>
                      </MenuItem>
                      <Confirm
                        onConfirm={async () => {
                          props.onNeedReload();
                        }}
                        colorScheme="orange"
                        confirmText="Geri Almayı Onayla"
                        text="Tamamlanan işlem geri alınacak. Bu işlemi onaylıyor musunuz?"
                      >
                        {({ onOpen }) => (
                          <MenuItem icon={<IoBackspace />} onClick={onOpen}>
                            <Text fontSize="xs">Geri Al</Text>
                          </MenuItem>
                        )}
                      </Confirm>
                    </MenuList>
                  </>
                )}
              </Menu>
            </>
          )}
        </Flex>
      </Flex>
      <Modal
        onClose={() => {
          props.onNeedReload();
        }}
        ref={modalRef}
        onConfirm={() => {
          modalRef.current?.close();
        }}
        onConfirmText="Tamam"
        title="Doküman Yükle"
      >
        <Flex flexDir="column" mt="3" pb="5">
          <Heading size="md">{props.data.demandName} Dokümanları</Heading>
          <Divider my="3" />
          {props.data.documents
            .filter((doc) =>
              props.onlyMe ? doc.assignUserID === user.id : true
            )
            .map((document, index) => (
              <Box
                mb="3"
                bgColor="gray.50"
                shadow="sm"
                p="3"
                rounded="sm"
                key={index}
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>{document.documentName}</Text>
                  {document.isCompleted ? (
                    <Badge colorScheme="green">Tamamlandı</Badge>
                  ) : (
                    <Badge colorScheme="orange">Doküman Yükleme Gerekli</Badge>
                  )}
                </Flex>
                <Box mb="2">
                  {document.isCompleted && (
                    <Button
                      size="sm"
                      mt="1"
                      onClick={() => {
                        window.open(document.path, "_blank");
                      }}
                    >
                      Dosyayı Gör
                    </Button>
                  )}
                  {role !== UserRoles.view && !document.isCompleted && (
                    <TimelineItemFormUpload
                      demand={props.data}
                      data={document}
                      onDrop={() => {
                        setLoading(true);
                      }}
                      onSuccess={() => {
                        setLoading(false);
                      }}
                    />
                  )}
                </Box>
                <Flex fontSize="sm">
                  <Text>Atanan Kullanıcı: </Text>
                  <Text>{document.assignUserName!}</Text>
                </Flex>
                {!document.isCompleted ? (
                  <Flex fontSize="xs">
                    <Text>Yüklenmesi Gereken Son Tarih: </Text>
                    <Text ml="1">
                      {Moment(document.lastDate).format("DD.MM.YYYY")}
                    </Text>
                  </Flex>
                ) : (
                  <Flex flexDir="column">
                    <Flex fontSize="xs">
                      <Text>Yüklenme Tarihi: </Text>
                      <Text ml="1">
                        {Moment(document.completedDate).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </Text>
                    </Flex>
                    <Flex fontSize="xs">
                      <Text color="gray.600">Son Yüklenebilir Tarih: </Text>
                      <Text color="gray.600" ml="1">
                        {Moment(document.lastDate).format("DD.MM.YYYY HH:mm")}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                <Divider mt="3" />
              </Box>
            ))}
        </Flex>
      </Modal>
    </Fragment>
  );

  return (
    <Fragment>
      <Flex
        p="3"
        py={2}
        border="1px"
        mb="1"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        justifyContent="space-between"
      >
        <Box fontSize="sm">
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              ID:{" "}
            </Text>
            {props.data?.demandID}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Talep:{" "}
            </Text>
            {props.data.demandName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Grup:{" "}
            </Text>
            {props.data.demandGroupName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Tesis:{" "}
            </Text>
            {props.data.campusName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Bina:{" "}
            </Text>
            {props.data.buildName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Kat:{" "}
            </Text>
            {props.data.floorName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Oda:{" "}
            </Text>
            {props.data.roomName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Kullanıcı Tipi:{" "}
            </Text>
            {props.data.userTypeName}
          </Text>
          <Text fontSize="xs">
            <Text d="inline" fontWeight="bold">
              Oluşturulma Tarihi:{" "}
            </Text>
            {Moment(props.data.demandCreateDate).format("DD.MM.YYYY")}
          </Text>
        </Box>
        <Flex flexDirection="column" alignItems="stretch">
          {isRequiredDoc && (
            <Badge colorScheme="orange" textAlign="center">
              Doküman Gerekli
            </Badge>
          )}
          {!isRequiredDoc && (
            <Badge colorScheme="green" textAlign="center">
              Tamamlandı
            </Badge>
          )}
          {isRequiredDoc && (
            <Button
              size="sm"
              mt="2"
              colorScheme="blue"
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              Doküman Yükle
            </Button>
          )}
          {!isRequiredDoc && (
            <Button
              size="sm"
              mt="2"
              colorScheme="teal"
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              Dokümanları Gör
            </Button>
          )}
        </Flex>
      </Flex>
      <Modal
        onClose={() => {
          props.onNeedReload();
        }}
        ref={modalRef}
        onConfirm={() => {
          modalRef.current?.close();
        }}
        onConfirmText="Tamam"
        title="Doküman Yükle"
      >
        <Flex flexDir="column" mt="3" pb="5">
          <Heading size="md">{props.data.demandName} Dokümanları</Heading>
          <Divider my="3" />
          {props.data.documents
            .filter((doc) =>
              props.onlyMe ? doc.assignUserID === user.id : true
            )
            .map((document, index) => (
              <Box
                mb="3"
                bgColor="gray.50"
                shadow="sm"
                p="3"
                rounded="sm"
                key={index}
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>{document.documentName}</Text>
                  {document.isCompleted ? (
                    <Badge colorScheme="green">Tamamlandı</Badge>
                  ) : (
                    <Badge colorScheme="orange">Doküman Yükleme Gerekli</Badge>
                  )}
                </Flex>
                <Box mb="2">
                  {document.isCompleted && (
                    <Button
                      size="sm"
                      mt="1"
                      onClick={() => {
                        window.open(document.path, "_blank");
                      }}
                    >
                      Dosyayı Gör
                    </Button>
                  )}
                  {role !== UserRoles.view && !document.isCompleted && (
                    <TimelineItemFormUpload
                      demand={props.data}
                      data={document}
                      onDrop={() => {
                        setLoading(true);
                      }}
                      onSuccess={() => {
                        setLoading(false);
                      }}
                    />
                  )}
                </Box>
                <Flex fontSize="sm">
                  <Text>Atanan Kullanıcı: </Text>
                  <Text>{document.assignUserName!}</Text>
                </Flex>
                {!document.isCompleted ? (
                  <Flex fontSize="xs">
                    <Text>Yüklenmesi Gereken Son Tarih: </Text>
                    <Text ml="1">
                      {Moment(document.lastDate).format("DD.MM.YYYY")}
                    </Text>
                  </Flex>
                ) : (
                  <Flex flexDir="column">
                    <Flex fontSize="xs">
                      <Text>Yüklenme Tarihi: </Text>
                      <Text ml="1">
                        {Moment(document.completedDate).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </Text>
                    </Flex>
                    <Flex fontSize="xs">
                      <Text color="gray.600">Son Yüklenebilir Tarih: </Text>
                      <Text color="gray.600" ml="1">
                        {Moment(document.lastDate).format("DD.MM.YYYY HH:mm")}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                <Divider mt="3" />
              </Box>
            ))}
        </Flex>
      </Modal>
    </Fragment>
  );
};

const TimelineItemTitle = () => {
  return (
    <Fragment>
      <Flex
        mb="2"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        p="2"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        top="0px"
        backgroundColor={useColorModeValue("gray.100", "gray.700")}
        zIndex="2"
      >
        <Flex alignItems="center" flex="1" ml="2">
          <Box flex="1" flexBasis="25px">
            <Text lineHeight="1" fontSize="x-small">
              ID
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Talep
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Grup
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Tesis
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="x-small">
              Kullanıcı Tipi
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="x-small">
              Oluşturulma Tarihi
            </Text>
          </Box>
        </Flex>

        <Flex minW="290px" justifyContent="flex-end" alignItems="center"></Flex>
      </Flex>
    </Fragment>
  );
};

interface TimelineItemFormUpload {
  data: ITimelineDemand["documents"][0];
  demand: ITimelineDemand;
  onSuccess?: () => void;
  onDrop?: () => void;
}
const TimelineItemFormUpload = ({
  data,
  demand,
  onSuccess,
  onDrop,
}: TimelineItemFormUpload) => {
  const [statu, setStatu] = useState<boolean>(false);
  const { user } = useAuth();
  const [upload, status, response] = Hooks.UploadDemandDocument();

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleDrop(files: File[]) {
    if (onDrop) {
      onDrop();
    }
    setStatu(true);
    const _file = files[0];
    let base64Str: string = (await toBase64(_file)) as string;
    await upload({
      completedUserID: user.id,
      demandDocumentID: data.demandDocumentID,
      document: base64Str!,
      extension: _file.name.split(".").pop(),
    });
    setStatu(false);
    if (onSuccess) {
      onSuccess();
    }
  }

  useEffect(() => {
    if (status === "fulfilled" && response?.data.success) {
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [status]);

  return (
    <Box>
      <Dropzone onDrop={handleDrop} loading={statu} hasAllType />
    </Box>
  );
};

TimelineDemand.defaultProps = {};

export default TimelineDemand;
