import {
  Button,
  Flex,
  Heading,
  Select,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import { CheckBoxElement, Form, InputElement } from "components/atoms/HookForm";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Hooks } from "services";

interface MaintenanceCreateProps {}

const MaintenanceCreate: FC<
  MaintenanceCreateProps &
    RouteComponentProps<{ id: string }, {}, { inventory: any }>
> = (props) => {
  const { project } = useAuth();
  const [quests, setQuests] = useState<any[]>([]);
  const [onAdd, status, dataAdd] = Hooks.AddMaintenance();
  const [getP, statusP, dataPeriod] = Hooks.ListPeriod();
  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: { isMaintenanceFirmRequired: true },
  });

  const [maintenanceType, setMaintenanceType] = useState<string>("0");
  const [periodType, setPeriodType] = useState<string>("0");
  const questFormRef = useRef<any>();
  const toast = useToast();
  const history = useHistory();

  function handleValid(values: any) {
    onAdd({
      projectID: project.id,
      inventoryID: parseInt(props.match.params.id),
      ...values,
      maintenanceTypeID: parseInt(maintenanceType),
      maintenancePeriodID: parseInt(periodType),
      beforeDay: parseInt(values.beforeDay),
    });
  }

  useEffect(() => {
    if (dataAdd?.data.success) {
      toast({ title: "İşlem Başarıyla oluşturuldu!", status: "success" });
      history.push(`/maintenance-list/${props.match.params.id}`, {
        inventory: props.location.state.inventory,
      });
    } else if (dataAdd?.data.success === false) {
      toast({
        title: "Bakım oluşturulamadı!",
        description: dataAdd?.data.message,
        status: "error",
      });
    }
  }, [dataAdd]);

  useLayoutEffect(() => {
    getP(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Bakım Ekle" }}>
      <Heading fontSize="lg">
        <Text d="inline" color="gray.500">
          Ekipman Adı
        </Text>
        :{" "}
        <Text d="inline" color="gray.600">
          {props.location.state.inventory.name}
        </Text>
        <Text ml="3" d="inline" color="gray.500">
          Ekipman Barkod No
        </Text>
        :{" "}
        <Text d="inline" color="gray.600">
          {props.location.state.inventory.barcode}
        </Text>
      </Heading>
      <Card mt="4">
        <Select
          value={maintenanceType}
          onChange={(e) => setMaintenanceType(e.currentTarget.value)}
        >
          <option value="0">Bakım Tipi Seçiniz</option>
          <option value="1">İç Bakım / Kontrol</option>
          <option value="2">Dış Bakım</option>
        </Select>
        <Select
          mt="3"
          value={periodType}
          onChange={(e) => setPeriodType(e.currentTarget.value)}
        >
          <option value="0">Periyot Seçiniz</option>
          {dataPeriod?.data.data.map((period: any) => (
            <option key={period.id} value={period.id}>
              {period.name}
            </option>
          ))}
        </Select>
        <Form onValid={handleValid}>
          <SimpleGrid columns={[1, 2]} spacing="5" mt="3">
            <InputElement
              label="Gerçekleşen Son Bakım Tarihi"
              type="date"
              name="startDate"
              isRequired
            />
            <InputElement
              label="Başlangıç Tarihinden Kaç Gün Önce Bakım Yapılabilir?"
              name="beforeDay"
              isRequired
            />
            <CheckBoxElement
              label="Kullanma talimatı zorunlu mu?"
              name="userInstructionsIsRequired"
            />
            <CheckBoxElement
              label="Kullanım klavuzu zorunlu mu?"
              name="userGudiePathIsRequired"
            />
            <CheckBoxElement
              label="ISG risk analizi zorunlu mu?"
              name="riskAnalysisIsRequired"
            />
            <CheckBoxElement
              label="Ekipman fotoğrafı zorunlu mu?"
              name="inventoryPhotoIdRequired"
            />
            <CheckBoxElement
              label="İş izin / bakım formu zorunlu mu?"
              name="controlFormPhotoIdRequired"
            />
            <CheckBoxElement
              label="Bakım sözleşmesi zorunlu mu?"
              name="maintenanceContractIsRequired"
            />
            <CheckBoxElement
              label="Bakım firması zorunlu mu?"
              name="isMaintenanceFirmRequired"
            />
          </SimpleGrid>
          {/* {maintenanceType === "1" && (
            <>
              <Heading fontSize="lg" mt="5">
                Sorular
              </Heading>
              <Divider my="2" />
              <SimpleGrid columns={[1, 2]} spacing="5">
                <Flex flexDir="column">
                  <Text>Soru Ekle</Text>
                  <Divider my="2" />
                  <Box>
                    <FormControl>
                      <Select placeholder="Soru Tipi" {...register("type")}>
                        <option value="1">Uygun / Uygun Değil</option>
                        <option value="2">Değer Girişli</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Textarea
                        mt="1"
                        placeholder="Soru"
                        {...register("name")}
                      />
                    </FormControl>
                    {watch("type") === "2" && (
                      <>
                        <FormControl mt="2">
                          <Input
                            {...register("start")}
                            placeholder="Başlangıç Değeri"
                          />
                        </FormControl>
                        <FormControl mt="2">
                          <Input
                            {...register("end")}
                            placeholder="Bitiş Değeri"
                          />
                        </FormControl>
                      </>
                    )}
                    {watch("type") === "1" && (
                      <FormControl mt="2">
                        <FormLabel>Olumlu Olan Yanıt</FormLabel>
                        <Select {...register("positive")}>
                          <option value="1">Uygun</option>
                          <option value="2">Uygun Değil</option>
                        </Select>
                      </FormControl>
                    )}

                    <Flex justifyContent="flex-end" mt="2">
                      <Button
                        size="sm"
                        rightIcon={<IoArrowForward />}
                        onClick={() =>
                          handleSubmit((values) => {
                            setQuests((_s) => [..._s, { ...values }]);
                            reset();
                          })()
                        }
                      >
                        Soru Ekle
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
                <Flex flexDir="column">
                  <Text>Soru Sırası</Text>
                  <Divider my="2" />
                  <SortableList
                    data={quests}
                    keyExtractor={(item) => Math.random().toString()}
                    renderItem={(item) => (
                      <Box
                        borderWidth="thin"
                        p="2"
                        bgColor="white"
                        borderRadius="md"
                        mb="1"
                        position="relative"
                      >
                        <Badge
                          position="absolute"
                          top="5px"
                          right="5px"
                          colorScheme={item.type === "1" ? "purple" : "teal"}
                        >
                          {item.type === "2"
                            ? "Değer Girişli"
                            : "Uygun / Uygun Değil"}
                        </Badge>
                        <Flex flexDir="column">
                          {item.name}
                          {item.type === "1" ? (
                            <Box>
                              <Badge display="inline">
                                Olumlu Yanıt:{" "}
                                {item.positive === "1"
                                  ? "Uygun"
                                  : "Uygun Değil"}
                              </Badge>
                            </Box>
                          ) : (
                            <Box>
                              Başlangıç Değeri: {item.start} - Bitiş Değeri:{" "}
                              {item.end}
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    )}
                    onChange={(newData) => setQuests(newData)}
                  />
                </Flex>
              </SimpleGrid>
            </>
          )} */}
          <Flex mt="5" justifyContent="flex-end">
            <Button colorScheme="teal" type="submit">
              Bakım Oluştur
            </Button>
          </Flex>
        </Form>
      </Card>
    </Main>
  );
};

MaintenanceCreate.defaultProps = {};

export default MaintenanceCreate;
