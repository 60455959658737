import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useForm, Controller } from "react-hook-form";
import { Hooks } from "services";
import Moment from "moment";
import { useAuth } from "hooks/redux-hooks";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToast } from "hooks/toast";

type CounterMaintenanceCreateProps = {};

const CounterMaintenanceCreate: FC<CounterMaintenanceCreateProps> = (props) => {
  const { project, user } = useAuth();
  const routeMatch = useRouteMatch<{ counterId: string }>();
  const routeHistory = useHistory();
  const toast = useToast();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      counterPeriodID: "1",
      startDate: Moment().format("YYYY-MM-DD"),
      isTRequired: false,
      isT1Required: false,
      isT2Required: false,
      isT3Required: false,
      isKapasitifRequired: false,
      isReaktifRequired: false,
      isDemantRequired: false,
      addedUserID: "0",
      beforeDay: "0",
      multiplierValue: 0,
      consumptionDifferencePercentage: 0,
    },
  });
  const [counter, setCounter] = useState<any>(null);

  const [getCL, statCL, dataCL] = Hooks.CounterListByProject();
  const [getPL, statPL, dataPL] = Hooks.CounterPeriodList();
  const [getUL, statUL, dataUL] = Hooks.UserList();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();

  const [create, stat, data] = Hooks.CounterCreateMaintenance();

  useEffect(() => {
    // getTL();
    getPL();
    getUL(project.id);
    getUT();

    if (!dataCL) {
      getCL(project.id);
    }
    
  }, []);

  useEffect(() => {
    if (dataCL) {
      const _counter = dataCL.data.data.find(
        (item: any) => item.id === Number(routeMatch.params.counterId)
      );
      if (_counter) setCounter(_counter);
    }
  }, [dataCL]);

  function onValid(values: any) {
    create({
      ...values,
      addedUserID: user.id,
      beforeDay: Number(values.beforeDay),
      counterID: counter.id,
      counterPeriodID: Number(values.counterPeriodID),
      startDate: values.startDate,
      multiplierValue: Number(values.multiplierValue),
      consumptionDifferencePercentage: Number(
        values.consumptionDifferencePercentage
      ),
    });
  }

  useEffect(() => {
    if (stat === "fulfilled") {
      if (data?.data.success) {
        toast({
          title: "Başarılı!",
          description: "Sayaç Bakımı Başarıyla Eklendi!",
          status: "success",
        });
        routeHistory.push(`/counter/${counter.id}/maintenance`);
      } else {
        toast({
          title: "Hata!",
          description: data?.data.message,
          status: "error",
        });
      }
    } else if (stat === "rejected") {
      toast({
        title: "Hata!",
        description: data?.data.message,
        status: "error",
      });
    }
  }, [stat]);

  return (
    <Main headerProps={{ title: "Sayaç Kontrolü Oluştur" }}>
      <Card>
        {statPL === "fulfilled" &&
        statCL === "fulfilled" &&
        statUT === "fulfilled" &&
        !!counter &&
        statUL === "fulfilled" ? (
          <form onSubmit={handleSubmit(onValid)}>
            <SimpleGrid columns={2} spacing="5">
              <Controller
                control={control}
                name="counterPeriodID"
                render={({ field }) => (
                  <FormControl id="counterPeriodID">
                    <FormLabel>Kontrol Periyodu </FormLabel>
                    <Select {...field}>
                      {dataPL?.data.data.map((opt: any, index: number) => (
                        <option key={index} value={opt.id}>
                          {opt.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {watch("counterPeriodID") !== "1" && (
                <>
                  <Controller
                    control={control}
                    name="startDate"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl id="startDate">
                        <FormLabel>Kontrol Tarihi </FormLabel>
                        <Input type="date" {...field} />
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="beforeDay"
                    render={({ field }) => (
                      <FormControl id="beforeDay">
                        <FormLabel>
                          Kontrol Kaç Gün Önce Yapılabilir?{" "}
                        </FormLabel>
                        <Input type="number" {...field} />
                      </FormControl>
                    )}
                  />
                </>
              )}
            </SimpleGrid>
            <Divider my="5" />
            {counter.counterTypeID === 1 && (
              <>
                <SimpleGrid columns={2} spacing="5">
                  <Controller
                    control={control}
                    name="isTRequired"
                    render={({ field }) => (
                      <FormControl id="isTRequired">
                        <FormLabel>T Zorunlu Mu </FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          T Zorunlu Mu?
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="isT1Required"
                    render={({ field }) => (
                      <FormControl id="isT1Required">
                        <FormLabel>T1 Zorunlu Mu </FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          T1 Zorunlu Mu?
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="isT2Required"
                    render={({ field }) => (
                      <FormControl id="isT2Required">
                        <FormLabel>T2 Zorunlu Mu </FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          T2 Zorunlu Mu?
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="isT3Required"
                    render={({ field }) => (
                      <FormControl id="isT3Required">
                        <FormLabel>T3 Zorunlu Mu </FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          T3 Zorunlu Mu?
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="isKapasitifRequired"
                    render={({ field }) => (
                      <FormControl id="isKapasitifRequired">
                        <FormLabel>Kapasitif Değer Zorunlu Mu</FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          Kapasitif Değer Zorunlu Mu?
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    defaultValue="1"
                    name="isReaktifRequired"
                    render={({ field }) => (
                      <FormControl id="isReaktifRequired">
                        <FormLabel>Reaktif Değer Zorunlu Mu </FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          Reaktif Değer Zorunlu Mu
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="isDemantRequired"
                    render={({ field }) => (
                      <FormControl id="isDemantRequired">
                        <FormLabel>Demant Değer Zorunlu Mu </FormLabel>
                        <Checkbox isChecked={field.value} {...(field as any)}>
                          Demant Değer Zorunlu Mu?
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
                <SimpleGrid columns={2} spacing="5" mt="5">
                  <Controller
                    control={control}
                    name="multiplierValue"
                    render={({ field }) => (
                      <FormControl id="aa">
                        <FormLabel>Çarpan Değeri </FormLabel>
                        <Input type="number" {...field} />
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="consumptionDifferencePercentage"
                    render={({ field }) => (
                      <FormControl id="bb">
                        <FormLabel>Ortalama Tüketim Farkı Yüzdesi (%)</FormLabel>
                        <Input type="number" {...field} />
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
                <Divider my="5" />
              </>
            )}
            <Flex justifyContent="flex-end" my="5">
              <Button type="submit" colorScheme="green">
                Oluştur
              </Button>
            </Flex>
          </form>
        ) : (
          <Center height="300px">
            <Spinner />
          </Center>
        )}
      </Card>
    </Main>
  );
};

export default CounterMaintenanceCreate;
