import React, { FC, useEffect, useLayoutEffect, useState, memo } from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import RSelect from "react-select";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { IoFilter } from "react-icons/io5";

interface InventoryFilterProps {
  onFilter: (data: any) => void;
  noGroups?: boolean;
}

const FilterSelect = memo((props: any) => {
  return (
    <FormControl>
      <FormLabel fontSize="sm">{props.label}</FormLabel>
      <RSelect
        isClearable
        isSearchable
        value={props.value}
        onChange={(value) => {
          props.onChange(value);
        }}
        isLoading={props.status === "pending"}
        placeholder="Seç"
        isDisabled={!props.data}
        options={props.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))}
      />
    </FormControl>
  );
});

const InventoryFilter: FC<InventoryFilterProps> = (props) => {
  const { project, user } = useAuth();
  const [getCampus, statCampus, dataCampus] = Hooks.CampusList();
  const [getBuild, statBuild, dataBuild, resetBuild] = Hooks.BuildList();
  const [getFloor, statFloor, dataFloor, resetFloor] = Hooks.FloorList();
  const [getRoom, statRoom, dataRoom, resetRoom] = Hooks.RoomList();
  const [getUserType, statUT, dataUT] = Hooks.UserTypeList();
  const [getGroup, statGroup, dataGroup] = Hooks.GroupList();
  const [getBrand, statBrand, dataBrand, resetBrand] = Hooks.BrandList();
  const [getModel, statModel, dataModel, resetModel] = Hooks.ModelList();

  const [filterData, setFilterData] = useState<any>({});

  useLayoutEffect(() => {
    getCampus(project.id);
    getUserType();
    getGroup(project.id);
  }, []);

  useEffect(() => {
    if (!!filterData?.campus) {
      setFilterData((state: any) => ({ ...state, build: undefined }));
      getBuild(filterData.campus.value);
    } else if (!filterData.campus && !!dataBuild) {
      resetBuild();
      setFilterData((state: any) => ({
        ...state,
        campus: undefined,
        build: undefined,
      }));
    }
  }, [filterData?.campus]);

  useEffect(() => {
    if (!!filterData?.build) {
      setFilterData((state: any) => ({ ...state, floor: undefined }));
      getFloor(filterData.build.value);
    } else if (!filterData?.build && !!dataFloor) {
      resetFloor();
      setFilterData((state: any) => ({ ...state, floor: undefined }));
    }
  }, [filterData?.build]);

  useEffect(() => {
    if (!!filterData?.floor) {
      setFilterData((state: any) => ({ ...state, room: undefined }));
      getRoom(filterData.floor.value);
    } else if (!filterData?.floor && !!dataRoom) {
      resetRoom();
      setFilterData((state: any) => ({ ...state, room: undefined }));
    }
  }, [filterData?.floor]);

  useEffect(() => {
    if (!!filterData?.group) {
      setFilterData((state: any) => ({ ...state, brand: undefined }));
      getBrand(filterData.group.value);
    } else if (!filterData.group && !!dataBrand) {
      resetBrand();
      setFilterData((state: any) => ({
        ...state,
        group: undefined,
        brand: undefined,
      }));
    }
  }, [filterData?.group]);

  useEffect(() => {
    if (!!filterData?.brand) {
      setFilterData((state: any) => ({ ...state, model: undefined }));
      getModel(filterData.brand.value);
    } else if (!filterData.brand && !!dataModel) {
      resetModel();
      setFilterData((state: any) => ({ ...state, model: undefined }));
    }
  }, [filterData?.brand]);

  return (
    <Card mb="3">
      <Flex>
        <IoFilter />
        <Text fontWeight="bold" fontSize="xs" ml="2">
          Filtre
        </Text>
      </Flex>
      <SimpleGrid columns={[5]} mt="3" gap="3">
        <FilterSelect
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, campus: value }));
          }}
          value={filterData.campus || null}
          data={dataCampus?.data?.data}
          status={statCampus}
          label="Tesis"
        />
        <FilterSelect
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, build: value }));
          }}
          value={filterData.build || null}
          data={dataBuild?.data?.data}
          status={statBuild}
          label="Bina"
        />
        <FilterSelect
          onChange={(value: any) => {
           
            setFilterData((state: any) => ({ ...state, floor: value }));
          }}
          value={filterData.floor || null}
          data={dataFloor?.data?.data}
          status={statFloor}
          label="Kat"
        />
        <FilterSelect
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, room: value }));
          }}
          value={filterData.room || null}
          data={dataRoom?.data?.data}
          status={statRoom}
          label="Oda"
        />
        <FilterSelect
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, userType: value }));
          }}
          value={filterData.userType || null}
          data={dataUT?.data?.data.filter((_item: any) =>
            user.userTypes.map((_: any) => _.id).includes(_item.id)
          )}
          status={statUT}
          label="Kullanıcı Tipi"
        />
        {!!!props.noGroups && (
          <>
            <FilterSelect
              onChange={(value: any) => {
                setFilterData((state: any) => ({ ...state, group: value }));
              }}
              value={filterData.group || null}
              data={dataGroup?.data?.data}
              status={statGroup}
              label="Grup"
            />
            <FilterSelect
              onChange={(value: any) => {
                setFilterData((state: any) => ({ ...state, brand: value }));
              }}
              value={filterData.brand || null}
              data={dataBrand?.data?.data}
              status={statBrand}
              label="Marka"
            />
            <FilterSelect
              onChange={(value: any) => {
                setFilterData((state: any) => ({ ...state, model: value }));
              }}
              value={filterData.model || null}
              data={dataModel?.data?.data}
              status={statModel}
              label="Model"
            />
          </>
        )}
      </SimpleGrid>
      <Flex mt="3" justifyContent="flex-end">
        <Button
          mr="2"
          size="sm"
          colorScheme="red"
          onClick={() => {
            setFilterData({});
            props.onFilter({});
          }}
        >
          Sıfırla
        </Button>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={() => {
            props.onFilter(filterData);
          }}
        >
          Filtrele
        </Button>
      </Flex>
    </Card>
  );
};

InventoryFilter.defaultProps = {};

export default InventoryFilter;
