import {
  Button, Center, Flex, FormControl,
  FormLabel, Spinner, useDisclosure
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import ModalAtom, { ModalProps } from "components/atoms/Modal";
import InventoryFilter from "components/organisms/InventoryFilter";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { Inventory } from "models";
import Moment from "moment";
import React, {
  FC,
  Fragment,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { FaFile } from "react-icons/fa";
import { RouteComponentProps } from "react-router-dom";
import RSelect from "react-select";
import { Hooks } from "services";

interface CompletedMaintenanceProps {}

const CompletedMaintenance: FC<
  CompletedMaintenanceProps & RouteComponentProps
> = (props) => {
  const { project } = useAuth();
  const [getReports, statReports, dataReports] = Hooks.CompletedReport();
  const [getReportsI, statReportsI, dataReportsI] = Hooks.InCompletedReport();

  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const [filterData2, setFilterData2] = useState<any>();
  const filterModalRef = useRef<ModalProps>(null);
  const [filteredType, settFilteredType] = useState(0);
  const [reportsF, setReportsF] = useState({
    completed: true,
    incompleted: true,
  });
  const filterDisc = useDisclosure();

  useLayoutEffect(() => {
    getReports(project.id);
    getReportsI(project.id);
    getPeriods(project.id);
    getUT();
  }, []);

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  function _onFilterData(row: Inventory) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData2?.campus?.label, row.campusName),
      _condition(filterData2?.build?.label, row.buildName),
      _condition(filterData2?.floor?.label, row.floorName),
      _condition(filterData2?.room?.label, row.roomName),
      _condition(filterData2?.userType?.value, row.userTypeID),
      _condition(filterData2?.group?.label, row.groupName),
      _condition(filterData2?.brand?.label, row.brandName),
      _condition(filterData2?.model?.label, row.modelName),
    ];

    return !_data.includes(false);
  }

  return (
    <Main headerProps={{ title: "Bakım Raporları" }}>
      {statReports !== "fulfilled" || !dataReports || !dataReportsI ? (
        <Center h="300px">
          <Spinner />
        </Center>
      ) : (
        <Fragment>
          <Card mb="3">
            <Flex justifyContent="space-between" alignItems="center">
              <Flex>
                <Button
                  size="sm"
                  border="1px"
                  variant={reportsF.completed ? "solid" : "outline"}
                  onClick={() => {
                    setReportsF((prev) => ({
                      ...prev,
                      completed: !prev.completed,
                    }));
                  }}
                  colorScheme="purple"
                  mr="1"
                >
                  Gerçekleşen
                </Button>
                <Button
                  border="1px"
                  size="sm"
                  variant={reportsF.incompleted ? "solid" : "outline"}
                  onClick={() => {
                    setReportsF((prev) => ({
                      ...prev,
                      incompleted: !prev.incompleted,
                    }));
                  }}
                  colorScheme="purple"
                  mr="1"
                >
                  Zamanında Gerçekleşmeyen
                </Button>
              </Flex>
              <Button
                size="sm"
                colorScheme="orange"
                onClick={() => filterDisc.onToggle()}
              >
                {filterDisc.isOpen ? "Filtre Kapat" : "Detaylı Filtre"}
              </Button>
            </Flex>
          </Card>

          {filterDisc.isOpen && (
            <InventoryFilter
              onFilter={(data: any) => {
                setFilterData2(data);
              }}
            />
          )}
          <Card>
            <RSelect
              options={[
                { label: "Hepsi", value: 0 },
                { label: "İç Bakım", value: 1 },
                { label: "Dış Bakım", value: 2 },
              ]}
              defaultValue={{ label: "Hepsi", value: 0 }}
              onChange={(value) => {
                settFilteredType(value?.value!);
              }}
            />
          </Card>
          <Card>
            <DataTable
              onClickFilter={() => {
                filterModalRef.current?.open();
              }}
              columns={[
                {
                  name: "Kullanıcı Tipi",
                  selector: "userTypeName",
                  width: "200px",
                },
                { name: "Barkod", selector: "barcode" },
                { name: "Ekipman Adı", selector: "name", width: "150px" },
                {
                  name: "Bakım Raporu",
                  cell: (row: any) => {
                    if (!row.isCompleted) {
                      return "Gerçekleşmedi";
                    }
                    return (
                      <Button
                        size="xs"
                        colorScheme="blue"
                        onClick={() => {
                          localStorage.removeItem("doc-report");
                          localStorage.setItem(
                            "doc-report",
                            JSON.stringify(row)
                          );
                          window.open("/doc-report", "_blank");
                        }}
                        leftIcon={<FaFile />}
                      >
                        Raporu Görüntüle
                      </Button>
                    );
                  },
                  width: "200px",
                },
                {
                  name: "Bakım Tipi",
                  selector: "maintenanceTypeName",
                  width: "150px",
                },
                {
                  name: "Periyot",
                  selector: "maintenancePeriodName",
                  width: "150px",
                },
                {
                  name: "Başlangıç Tarihi",
                  selector: "startDateStr",
                  width: "150px",
                },
                {
                  name: "Planlı Bakım Tarihi",
                  selector: "endDateStr",
                  width: "150px",
                },
                {
                  name: "Gerçekleşen Tarih",
                  selector: "maintenanceDateStr",
                  cell: (row: any) => {
                    if (!row.isCompleted) {
                      return "Gerçekleşmedi";
                    }

                    return row.maintenanceDateStr;
                  },
                  width: "150px",
                },
                {
                  name: "Bakım Firması",
                  selector: "maintenanceFirm",
                  width: "150px",
                },
                { name: "Kullanıcı", selector: "personelName", width: "150px" },
                { name: "Tesis", selector: "campusName", width: "150px" },
                { name: "Bina", selector: "buildName", width: "150px" },
                { name: "Kat", selector: "floorName", width: "150px" },
                { name: "Oda", selector: "roomName", width: "150px" },
                { name: "Grup", selector: "groupName", width: "150px" },
                { name: "Marka", selector: "brandName", width: "150px" },
                { name: "Model", selector: "modelName", width: "150px" },
                {
                  name: "Kapasite Tipi",
                  selector: "inventoryCapacityName",
                  width: "150px",
                },
                {
                  name: "Kapasite Birimi",
                  selector: "unit",
                  width: "150px",
                },
                { name: "Adet", selector: "count", width: "100px" },
                {
                  name: "Üretim Yılı",
                  selector: "productionYear",
                  width: "150px",
                },
              ]}
              data={[
                ...(reportsF.completed
                  ? dataReports.data.data.map((item: any) => ({
                      ...item,
                      isCompleted: true,
                      maintenanceDateStr: item.maintenanceDateStr,
                    }))
                  : []),
                ...(reportsF.incompleted
                  ? dataReportsI.data.data.map((item: any) => ({
                      ...item,
                      isCompleted: false,
                      maintenanceDateStr: "Gerçekleşmedi",
                    }))
                  : []),
              ]
                .filter(onFilter)
                .filter(_onFilterData)
                .filter((item: any) =>
                  filteredType === 0
                    ? true
                    : item.maintenanceTypeID === filteredType
                )
                .map((row) => ({
                  ...row,
                  startDateStr: Moment(row.startDate).format("DD.MM.YYYY"),
                  endDateStr: Moment(row.endDate).format("DD.MM.YYYY"),
                  maintenanceDateStr: Moment(row.maintenanceDate).format(
                    "DD.MM.YYYY HH:mm"
                  ),
                }))}
            />
          </Card>
        </Fragment>
      )}
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl>
          <FormLabel>Periyot</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            defaultValue={
              filterData.find((item) => item.name === "maintenancePeriodID")
                ?.selection
            }
            onChange={(value) => {
              const keyName = "maintenancePeriodID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                    selection: value,
                  },
                ]);
              }
            }}
            options={dataPeriods?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
        <FormControl mt="5">
          <FormLabel>Bakım Tipi</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            defaultValue={
              filterData.find((item) => item.name === "maintenanceTypeID")
                ?.selection
            }
            onChange={(value) => {
              const keyName = "maintenanceTypeID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                    selection: value,
                  },
                ]);
              }
            }}
            options={[
              {
                label: "İç Bakım / Konrol",
                value: 1,
              },
              {
                label: "Dış Bakım",
                value: 2,
              },
            ]}
          />
        </FormControl>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            defaultValue={
              filterData.find((item) => item.name === "userTypeId")?.selection
            }
            onChange={(value) => {
              const keyName = "userTypeId";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                    selection: value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

CompletedMaintenance.defaultProps = {};

export default CompletedMaintenance;
