import React, { useLayoutEffect, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Spinner,
  Center,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import Modal, { ModalProps } from "components/atoms/Modal";
import {
  MyForm,
  InputField,
  FormProps,
  SelectField,
} from "components/molecules";
import { useAuth } from "hooks/redux-hooks";
import { TextareaField } from "components/molecules/Form/InputField";
import { Hooks } from "services";
import { useToast } from "hooks/toast";
import DTable from "components/atoms/DataTable";
import { Campus as ICampus } from "services/swagger";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { useHistory } from "react-router";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";
import { UserRoles } from "models/user";
import LocationUpdater from "components/organisms/LocationUpdater";

function Campus() {
  const modalRef = useRef<ModalProps>(null);
  const formRef = useRef<FormProps<any>>(null);
  const { project, role } = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [create] = Hooks.CampusCreate();
  const [onEdit] = Hooks.EditCampus();
  const [getList, statusList, data] = Hooks.CampusList();

  async function handleClickToggleActive(row: any) {
    const resp = (await onEdit({
      ...row,
      isActive: !row.isActive,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    getList(project.id);
    toast({ status: "success", title: "Kampüs Düzenlendi!" });
  }

  async function handleUpdateTitle(row: any, title: string) {
    const resp = (await onEdit({
      ...row,
      name: title,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }
    getList(project.id);
    toast({ status: "success", title: "Kampüs Düzenlendi!" });
  }

  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Kampüs" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.view && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => modalRef.current?.open()}
            >
              Yeni Kampüs Ekle
            </Button>
          )}
        </Flex>
      </Card>

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Kampüs Adı",
                selector: "name",
                sortable: true,
              },
              {
                name: "Adres",
                selector: "address",
                sortable: true,
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={() => {
                        handleClickToggleActive(row);
                      }}
                      text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: ICampus) => (
                  <LocationUpdater
                    title="Kampüs Düzenle"
                    defaultData={{ title: row.name! }}
                    onSubmit={(title) => handleUpdateTitle(row, title)}
                  />
                ),
              },
              {
                name: "Binalar",
                cell: (row: ICampus) => (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      history.push(`/campus/${row.id}/build`);
                    }}
                  >
                    Binalar
                  </Button>
                ),
              },
            ]}
            data={data?.data.data}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      <Modal
        ref={modalRef}
        title="Kampüs Ekle"
        onConfirm={() => {
          formRef.current?.submitForm();
        }}
      >
        <MyForm
          innerRef={formRef}
          initialValues={{ name: "", address: "" }}
          onSubmit={async ({ name, address }) => {
            const data = (await create({
              name,
              address,
              isActive: true,
              projectID: project.id,
            })) as unknown as any;
            if (data?.data?.success) {
              toast({ title: "Kampüs Oluşturuldu", status: "success" });
              formRef.current?.resetForm();
              modalRef.current?.close();
              getList(project.id);
            }
          }}
        >
          <InputField name="name" label="Kampüs Adı" required />
          <TextareaField
            name="address"
            label="Adres"
            containerProps={{ mt: "3" }}
          />
        </MyForm>
      </Modal>
    </Main>
  );
}

export default Campus;
