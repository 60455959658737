import React from "react";
import { Text, Flex, Box, Icon, Divider } from "@chakra-ui/react";
import { Card } from "components/atoms";
import { useHistory } from "react-router-dom";

function AdvancedNumberWidget({
  title,
  value,
  valueType,
  icon,
  color,
  link,
  extraData,
}: any) {
  const history = useHistory();
  return (
    <Card
      h="140px"
      w="100%"
      border="1px"
      borderColor="gray.200"
      cursor="pointer"
      userSelect="none"
      _hover={{ background: "gray.50" }}
      onClick={() => {
        history.push(link);
      }}
    >
      <Flex alignItems="center" h="100%" justifyContent="space-between">
        <Flex flexDir="column" w="100%">
          <Flex justifyContent="space-between" alignItems="flx-end">
            <Flex flexDir="column">
              <Text
                fontSize="small"
                textTransform="uppercase"
                fontWeight="semibold"
                color="gray.500"
              >
                {title}
              </Text>
              <Text
                fontSize="xl"
                fontWeight="semibold"
                color="black"
                textTransform="uppercase"
              >
                {value} <small>{valueType}</small>
              </Text>
            </Flex>
            <Box>
              <Icon as={icon} fontSize="4xl" mt="1" color={`${color}.500`} />
            </Box>
          </Flex>
          <Divider w="100%" my="1" />
          <Flex mt="2">
            {extraData?.map((item: any, index: number) => (
              <Box flex={1} mr="1" key={index}>
                {!!!item?.not && (
                  <Text fontSize="xs" mb="-1">
                    Gerçekleşmeyen
                  </Text>
                )}
                <Text fontSize="xs">{item.title}</Text>
                <Text color={item?.not ? "blue.600" : "red.600"} fontSize="sm">
                  {item.value} adet
                </Text>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default AdvancedNumberWidget;
