// #region Imports
import React, {
  FC,
  Fragment,
  memo,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  Spinner,
  Badge,
  Heading,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  IconButton,
  SimpleGrid,
  Checkbox,
  forwardRef,
  Tooltip,
  Input,
  Collapse,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
} from "@chakra-ui/react";
import { Card, Modal, ModalProps } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { TimelineItem, TimelineItemDetail } from "models";
import Moment from "moment";
import PerfectScrollbar, {
  ScrollBarProps,
} from "components/atoms/PerfectScrollbar";
import { getColorDescription } from "utils/maintenanceColors";
import { useHistory } from "react-router-dom";
import { FaFilter, FaInfo } from "react-icons/fa";
import { colors } from "utils/maintenanceColors";
import {
  IoAlbumsOutline,
  IoArrowDown,
  IoAttach,
  IoBackspace,
  IoCheckmark,
  IoChevronDown,
  IoEllipsisHorizontal,
  IoFileTray,
  IoFilter,
  IoFilterCircle,
  IoInformation,
  IoRemove,
  IoSearch,
  IoTrash,
  IoWarning,
} from "react-icons/io5";
import Dropzone from "components/atoms/Dropzone";
import { useToast } from "hooks/toast";
import { IoMdClock } from "react-icons/io";
import Confirm from "components/atoms/Confirm";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import moment from "moment";
// #endregion

enum MaintenanceStatus {
  done = 1,
  undone = 2,
  late = 6,
  lateDone = 7,
  wait = 2,
  not = 3,
  ready = 4,
}

interface TimelineWidgetProps {
  renderChangeDropdown?: () => ReactElement;
  hidden: boolean;
  hiddenFuture: boolean;
  selectedCampus: number;
  onChangeCount: (count: number) => void;
}

const TimelineWidget: FC<TimelineWidgetProps> = (props) => {
  const { project, user } = useAuth();
  const _ref = useRef<any>(null);
  const [getTimeline, statTimeline, dataTimeline] = Hooks.MaintenanceTimeline();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriodStore();
  const [getReports, statReports, dataReports] = Hooks.CompletedReport();
  const [getTypes, statType, dataTypes] = Hooks.UserTypeList();
  const filterModalRef = useRef<ModalProps>(null);
  const [query, setQuery] = useState<string>("");
  const [isSearch, setSearch] = useState(false);
  const [iStat, setIStat] = useState<boolean | null>(null);

  const [filter, setFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
  });

  const [currentFilter, setCurrentFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
  });

  useLayoutEffect(() => {
    getTimeline(project.id);
    getTypes(project.id);
    getReports(project.id);
    if (!dataPeriods) {
      getPeriods(project.id);
    }
  }, []);

  function handleFilter() {
    setCurrentFilter(filter);
    filterModalRef.current?.close();
  }

  function clearFilter() {
    setCurrentFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
    });
    setFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
    });
    filterModalRef.current?.close();
  }

  function onFilter(item: TimelineItem) {
    let filterArr = [];
    if (currentFilter.status.length !== 0) {
      filterArr.push(
        currentFilter.status.includes(
          item.maintenanceTransactionStatusColorCode
        )
      );
    }

    if (currentFilter.period.length !== 0) {
      filterArr.push(currentFilter.period.includes(item.maintenancePeriodID));
    }

    if (currentFilter.types.length !== 0) {
      filterArr.push(currentFilter.types.includes(item.maintenanceTypeID));
    }

    if (currentFilter.userTypes.length !== 0) {
      filterArr.push(
        currentFilter.userTypes.includes((item as any).userTypeId)
      );
    }

    if (iStat !== null) {
      filterArr.push(item.isMaintenanceComplete === iStat);
    }

    if (props.hidden) {
      filterArr.push(!item.isMaintenanceComplete);
    }

    if (props.selectedCampus !== 0) {
      filterArr.push(props.selectedCampus === (item as any).campusID);
    }

    return (
      !filterArr.includes(false) &&
      item.inventoryName.toLowerCase().includes(query.toLowerCase())
    );
  }

  useEffect(() => {
    if (dataTimeline?.data.data) {
      const count = dataTimeline?.data.data.filter(onFilter).length;
      props.onChangeCount(count);
    }
  }, [filter, props.hiddenFuture, props.hidden, dataTimeline, currentFilter]);

  return (
    <Card w="100%" minH="calc(100vh - 200px)">
      <Flex mb="2" justifyContent="space-between">
        <Box>{props.renderChangeDropdown && props.renderChangeDropdown()}</Box>
        <Flex alignItems="center">
          <Tooltip label="Filtre" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="1"
              aria-label="Filtre"
              icon={<IoFilter />}
              onClick={() => {
                filterModalRef.current?.open();
              }}
            />
          </Tooltip>
          <Tooltip label="Geçmiş" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoArrowDown />}
              onClick={() => {
                _ref.current?.goUndone();
              }}
              aria-label="Geçmiş"
            />
          </Tooltip>
          <Tooltip label="Bugüne Git" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoMdClock />}
              onClick={() => {
                _ref.current?.goToday();
              }}
              aria-label="Bugün"
            />
          </Tooltip>
          <Tooltip label="Ara" aria-label="Search tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoSearch />}
              onClick={() => {
                if (isSearch) {
                  setQuery("");
                  setSearch(false);
                } else {
                  setSearch(true);
                }
              }}
              aria-label="Ara"
            />
          </Tooltip>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Box>
                <Tooltip label="Renk Açıklamaları" aria-label="Colors tooltip">
                  <IconButton
                    size="sm"
                    aria-label="colors"
                    icon={<IoInformation />}
                  />
                </Tooltip>
              </Box>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Bakım Renk Açıklamaları</PopoverHeader>
              <PopoverBody>
                {colors.map((color, index) => (
                  <Flex key={color.color} alignItems="center" mb="1">
                    <Box
                      w="20px"
                      h="20px"
                      mr="2"
                      bgColor={color.color}
                      rounded="sm"
                    ></Box>
                    <Text>{color.desc}</Text>
                  </Flex>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
      <Box>
        <Collapse in={isSearch} animateOpacity>
          <Box>
            <Input
              size="sm"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              disabled={!isSearch}
              placeholder="Ekipman Adına Göre Ara..."
            />
          </Box>
        </Collapse>
      </Box>

      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="semibold" mb="1" mt="2">
          Bakım İşlemleri
        </Text>
        <Flex>
          <Tooltip label="Gerçekleşen">
            <IconButton
              size="xs"
              colorScheme="green"
              variant={iStat === true ? "solid" : "outline"}
              aria-label="complete"
              onClick={() => {
                setIStat(true);
              }}
              icon={<IoCheckmark />}
            />
          </Tooltip>
          <Tooltip label="Hepsi">
            <IconButton
              mx="1"
              size="xs"
              aria-label="complete"
              variant={iStat === null ? "solid" : "outline"}
              onClick={() => {
                setIStat(null);
              }}
              icon={<IoAlbumsOutline />}
            />
          </Tooltip>
          <Tooltip label="Gerçekleşmeyen">
            <IconButton
              size="xs"
              aria-label="complete"
              variant={iStat === false ? "solid" : "outline"}
              onClick={() => {
                setIStat(false);
              }}
              colorScheme="red"
              icon={<IoRemove />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Box h="100%">
        {statTimeline !== "fulfilled" && (
          <Center h="100%">
            <Spinner />
          </Center>
        )}
        {statTimeline === "fulfilled" && (
          <Timeline
            onNeedReload={() => {
              getTimeline(project.id);
            }}
            ref={_ref}
            data={dataTimeline!.data!.data!.filter(onFilter)}
            reports={dataReports}
          />
        )}
      </Box>
      <Modal
        ref={filterModalRef}
        noForm
        title="Filtre"
        onClose={() => {}}
        onOpen={() => {
          setFilter(currentFilter);
        }}
      >
        <Box color="gray.600">
          <Heading color="gray.600" mt="-3" fontSize="md">
            Bakım Durumu
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {colors.map((_color) => (
              <Flex
                key={_color.color}
                alignItems="center"
                borderWidth="1px"
                px="3"
                py="1"
              >
                <Checkbox
                  defaultChecked={filter.status.includes(_color.color)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.status.includes(_color.color)) {
                        setFilter((_state) => ({
                          ..._state,
                          status: [..._state.status, _color.color],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        status: _state.status.filter(
                          (_statu) => _statu !== _color.color
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_color.desc}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading color="gray.600" mt="3" fontSize="md">
            Kullanıcı Tipi
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataTypes?.data.data
              .filter((_period: any) =>
                user.userTypes.map((_: any) => _.id).includes(_period.id)
              )
              .map((_period: any) => (
                <Flex
                  key={_period}
                  alignItems="center"
                  borderWidth="1px"
                  px="3"
                  py="2"
                >
                  <Checkbox
                    defaultChecked={filter.userTypes.includes(_period.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        if (!filter.userTypes.includes(_period.id)) {
                          setFilter((_state) => ({
                            ..._state,
                            userTypes: [..._state.userTypes, _period.id],
                          }));
                        }
                      } else {
                        setFilter((_state) => ({
                          ..._state,
                          userTypes: _state.userTypes.filter(
                            (_periodId) => _periodId !== _period.id
                          ),
                        }));
                      }
                    }}
                  >
                    <Text fontSize="xs" lineHeight="4">
                      {_period.name}
                    </Text>
                  </Checkbox>
                </Flex>
              ))}
          </SimpleGrid>
          <Heading color="gray.600" mt="3" fontSize="md">
            Periyot
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataPeriods?.data.data.map((_period: any) => (
              <Flex
                key={_period}
                alignItems="center"
                borderWidth="1px"
                px="3"
                py="2"
              >
                <Checkbox
                  defaultChecked={filter.period.includes(_period.id)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.period.includes(_period.id)) {
                        setFilter((_state) => ({
                          ..._state,
                          period: [..._state.period, _period.id],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        period: _state.period.filter(
                          (_periodId) => _periodId !== _period.id
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_period.name}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading color="gray.600" mt="3" fontSize="md">
            Bakım Tipi
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2]} gap="1">
            <Flex alignItems="center" borderWidth="1px" px="3" py="2">
              <Checkbox
                defaultChecked={filter.types.includes(1)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    if (!filter.types.includes(1)) {
                      setFilter((_state) => ({
                        ..._state,
                        types: [..._state.types, 1],
                      }));
                    }
                  } else {
                    setFilter((_state) => ({
                      ..._state,
                      period: _state.types.filter((_type) => _type !== 1),
                    }));
                  }
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  İç Bakım / Kontrol
                </Text>
              </Checkbox>
            </Flex>
            <Flex alignItems="center" borderWidth="1px" px="3" py="2">
              <Checkbox
                defaultChecked={filter.types.includes(2)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    if (!filter.types.includes(2)) {
                      setFilter((_state) => ({
                        ..._state,
                        types: [..._state.types, 2],
                      }));
                    }
                  } else {
                    setFilter((_state) => ({
                      ..._state,
                      period: _state.types.filter((_type) => _type !== 2),
                    }));
                  }
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  Dış Bakım
                </Text>
              </Checkbox>
            </Flex>{" "}
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="10">
            <Button
              leftIcon={<IoTrash />}
              onClick={clearFilter}
              mr="2"
              colorScheme="red"
              variant="outline"
              size="sm"
            >
              Temizle
            </Button>
            <Button
              leftIcon={<IoFilter />}
              colorScheme="blue"
              minW="200px"
              onClick={handleFilter}
              size="sm"
            >
              Filtre
            </Button>
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
};

interface TimelineProps {
  data: TimelineItem[];
  onNeedReload: any;
}

function ItemTitle(props: any) {
  return (
    <Flex
      mb="2"
      border="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p="2"
      justifyContent="space-between"
      alignItems="center"
      id={`item-${props.index}`}
      position="sticky"
      top="0px"
      backgroundColor={useColorModeValue("gray.100", "gray.700")}
      zIndex="2"
    >
      <Flex
        flexDir="column"
        alignItems="center"
        w="100px"
        pr="0"
        borderRight="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        mr="3"
      >
        <Text lineHeight="1" fontSize="x-small">
          Tarih
        </Text>
      </Flex>
      <Flex alignItems="center" flex="1">
        <Box rounded="full" w="10px" h="10px" mr="4"></Box>
        <Box flex="1" flexBasis="25px">
          <Text lineHeight="1" fontSize="x-small">
            ID
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Ekipman
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Tesis
          </Text>
        </Box>
        {/* <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Bina
          </Text>
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.item.buildName}
          </Text>
        </Box> */}
        {/* <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Kat
          </Text>
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.floorName}
          </Text>
        </Box>
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Oda
          </Text>
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.roomName}
          </Text>
        </Box> */}
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Periyot
          </Text>
        </Box>
        {/* <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Barkod
          </Text>
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.barcode}
          </Text>
        </Box> */}
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Bakım Tipi
          </Text>
        </Box>
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Planlı Bakım Tarihi
          </Text>
        </Box>
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Kullanıcı Tipi
          </Text>
        </Box>
      </Flex>
      <Flex minW="290px" justifyContent="flex-end" alignItems="center"></Flex>
    </Flex>
  );
  // return (
  //   <Flex
  //     mb="2"
  //     border="1px"
  //     borderColor={useColorModeValue("gray.100", "gray.700")}
  //     p="2"
  //     justifyContent="space-between"
  //     id={`item-${props.index}`}
  //   >
  //     <Flex>
  //       <Flex
  //         flexDir="column"
  //         alignItems="center"
  //         w="100px"
  //         pr="2"
  //         borderRight="1px"
  //         borderColor={useColorModeValue("gray.100", "gray.700")}
  //         mr="3"
  //         pt="4"
  //       >
  //         <Text lineHeight="7" fontSize="2xl">
  //           {Moment(props.item.endDate).format("DD")}
  //         </Text>
  //         <Text>{Moment(props.item.endDate).format("MMMM")}</Text>
  //       </Flex>
  //       <Box>
  //         <Flex alignItems="center">
  //           <Box
  //             rounded="full"
  //             bgColor={props.item.maintenanceTransactionStatusColorCode}
  //             w="20px"
  //             h="20px"
  //             mr="2"
  //           ></Box>
  //           <Text fontSize="xs">{props.item.inventoryName}</Text>
  //         </Flex>
  //         <Text mt="2" fontSize="xs">
  //           ID: {props.item.id}
  //         </Text>
  //         <Text fontSize="xs">Tesis: {props.item.campusName}</Text>
  //         <Text fontSize="xs">Bina: {props.item.buildName}</Text>
  //         <Text fontSize="xs">Kat: {props.item.floorName}</Text>
  //         <Text fontSize="xs">Oda: {props.item.roomName}</Text>
  //         <Text fontSize="xs">Periyot: {props.item.maintenancePeriodName}</Text>
  //         <Text fontSize="xs">Barkod: {props.item.barcode}</Text>
  //         <Text fontSize="xs">
  //           Bakım Tipi: {props.item.maintenanceTypeName}
  //         </Text>
  //         <Text fontSize="xs">
  //           Planlı Bakım Tarihi:{" "}
  //           {Moment(props.item.endDate).format("DD.MM.YYYY")}
  //         </Text>
  //         <Text fontSize="xs">
  //           Kullanıcı Tipi:{" "}
  //           <Text d="inline" color="blue.500">
  //             {(props.item as unknown as any)?.userTypeName}
  //           </Text>
  //         </Text>
  //         <Text fontSize="x-small">
  //           Durum: {props.item.maintenanceTransactionStatusDescription}
  //         </Text>
  //       </Box>
  //     </Flex>
  //     <Flex flexDir="column">
  //       <Button
  //         size="sm"
  //         colorScheme="blue"
  //         mb="2"
  //         onClick={() => {
  //           props.setDetail(props.item);
  //           props.current?.open();
  //         }}
  //       >
  //         Detay
  //       </Button>
  //       {[MaintenanceStatus.ready, MaintenanceStatus.late].includes(
  //         props.item.maintenanceTransactionStatus
  //       ) && (
  //         <Button
  //           colorScheme="teal"
  //           size="sm"
  //           onClick={() => {
  //             props.push(`/maintenance/do-maintenance/${props.item.id}`, {
  //               timelineItem: props.item,
  //             });
  //           }}
  //         >
  //           Bakım Yap
  //         </Button>
  //       )}
  //       {props.item.isMaintenanceComplete && (
  //         <Badge colorScheme="green">Bakım Yapıldı</Badge>
  //       )}
  //       {props.item.isMaintenanceComplete && (
  //         <Button
  //           colorScheme="pink"
  //           mt="2"
  //           size="sm"
  //           onClick={props.onClickReport}
  //         >
  //           Raporu Gör
  //         </Button>
  //       )}
  //     </Flex>
  //   </Flex>
  // );
}

function Item(props: any) {
  const _item: TimelineItem = props.item;
  return (
    <Flex
      mb="2"
      border="1px"
      minH={90}
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p="2"
      justifyContent="space-between"
      alignItems="center"
      id={`item-${props.index}`}
    >
      <Flex
        flexDir="column"
        alignItems="center"
        w="100px"
        pr="0"
        borderRight="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        mr="3"
      >
        <Text lineHeight="1" fontSize="xs">
          {Moment(props.item.endDate).format("DD")}
        </Text>
        <Text fontSize="xs">{Moment(props.item.endDate).format("MMMM")}</Text>
      </Flex>
      <Flex alignItems="center" flex="1">
        <Center flexDir="column" mr="4">
          <Tooltip label={_item.maintenanceTransactionStatusDescription}>
            <Box
              rounded="full"
              bgColor={props.item.maintenanceTransactionStatusColorCode}
              w="10px"
              h="10px"
            ></Box>
          </Tooltip>
          {_item.lastCanceledDate && (
            <>
            <Divider w="20px" mt="2" />
            <Box>
              <Tooltip
                label={`Bu bakım ${moment(_item.lastCanceledDate).format(
                  "DD/MM/YYYY"
                )} tarihinde iptal edildi`}
              >
                <Box>
                  <Icon color="red.600" as={IoWarning} />
                </Box>
              </Tooltip>
            </Box></>
          )}
        </Center>
        <Box flex="1" flexBasis="25px">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.item.id}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.inventoryName}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.campusName}
          </Text>
        </Box>
        {/* <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Bina
          </Text>
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.item.buildName}
          </Text>
        </Box> */}
        {/* <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Kat
          </Text>
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.floorName}
          </Text>
        </Box>
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Oda
          </Text>
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.roomName}
          </Text>
        </Box> */}
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.item.maintenancePeriodName}
          </Text>
        </Box>
        {/* <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="x-small">
            Barkod
          </Text>
          <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
            {props.item.barcode}
          </Text>
        </Box> */}
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.item.maintenanceTypeName}
          </Text>
        </Box>
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {Moment(props.item.endDate).format("DD.MM.YYYY")}
          </Text>
        </Box>
        <Box ml="2" flex="2">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {(props.item as unknown as any)?.userTypeName}
          </Text>
        </Box>
      </Flex>
      <Flex minW="290px" justifyContent="flex-end" alignItems="center">
        <Button
          size="xs"
          colorScheme="blue"
          ml="2"
          onClick={() => {
            props.setDetail(props.item);
            props.current?.open();
          }}
        >
          Detay
        </Button>
        {[MaintenanceStatus.ready, MaintenanceStatus.late].includes(
          props.item.maintenanceTransactionStatus
        ) && (
          <Button
            colorScheme="teal"
            size="xs"
            ml="2"
            onClick={() => {
              props.push(`/maintenance/do-maintenance/${props.item.id}`, {
                timelineItem: props.item,
              });
            }}
          >
            Bakım Yap
          </Button>
        )}
        {props.item.isMaintenanceComplete && (
          <>
            <Badge py="1" colorScheme="green" ml="2">
              Bakım Yapıldı
            </Badge>
            <Menu placement="bottom-end">
              {({ isOpen }) => (
                <>
                  <MenuButton
                    ml="2"
                    isActive={isOpen}
                    as={IconButton}
                    size="sm"
                    icon={<IoEllipsisHorizontal />}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<IoFileTray />}
                      onClick={props.onClickReport}
                    >
                      <Text fontSize="xs">Raporu Gör</Text>
                    </MenuItem>
                    <Confirm
                      onConfirm={props.onClickCancel}
                      colorScheme="orange"
                      confirmText="Geri Almayı Onayla"
                      text="Gerçekleşen bakım geri alınacak. Bu işlemi onaylıyor musunuz?"
                    >
                      {({ onOpen }) => (
                        <MenuItem icon={<IoBackspace />} onClick={onOpen}>
                          <Text fontSize="xs">Geri Al</Text>
                        </MenuItem>
                      )}
                    </Confirm>
                  </MenuList>
                </>
              )}
            </Menu>
          </>
        )}
      </Flex>
    </Flex>
  );
  // return (
  //   <Flex
  //     mb="2"
  //     border="1px"
  //     borderColor={useColorModeValue("gray.100", "gray.700")}
  //     p="2"
  //     justifyContent="space-between"
  //     id={`item-${props.index}`}
  //   >
  //     <Flex>
  //       <Flex
  //         flexDir="column"
  //         alignItems="center"
  //         w="100px"
  //         pr="2"
  //         borderRight="1px"
  //         borderColor={useColorModeValue("gray.100", "gray.700")}
  //         mr="3"
  //         pt="4"
  //       >
  //         <Text lineHeight="7" fontSize="2xl">
  //           {Moment(props.item.endDate).format("DD")}
  //         </Text>
  //         <Text>{Moment(props.item.endDate).format("MMMM")}</Text>
  //       </Flex>
  //       <Box>
  //         <Flex alignItems="center">
  //           <Box
  //             rounded="full"
  //             bgColor={props.item.maintenanceTransactionStatusColorCode}
  //             w="20px"
  //             h="20px"
  //             mr="2"
  //           ></Box>
  //           <Text fontSize="xs">{props.item.inventoryName}</Text>
  //         </Flex>
  //         <Text mt="2" fontSize="xs">
  //           ID: {props.item.id}
  //         </Text>
  //         <Text fontSize="xs">Tesis: {props.item.campusName}</Text>
  //         <Text fontSize="xs">Bina: {props.item.buildName}</Text>
  //         <Text fontSize="xs">Kat: {props.item.floorName}</Text>
  //         <Text fontSize="xs">Oda: {props.item.roomName}</Text>
  //         <Text fontSize="xs">Periyot: {props.item.maintenancePeriodName}</Text>
  //         <Text fontSize="xs">Barkod: {props.item.barcode}</Text>
  //         <Text fontSize="xs">
  //           Bakım Tipi: {props.item.maintenanceTypeName}
  //         </Text>
  //         <Text fontSize="xs">
  //           Planlı Bakım Tarihi:{" "}
  //           {Moment(props.item.endDate).format("DD.MM.YYYY")}
  //         </Text>
  //         <Text fontSize="xs">
  //           Kullanıcı Tipi:{" "}
  //           <Text d="inline" color="blue.500">
  //             {(props.item as unknown as any)?.userTypeName}
  //           </Text>
  //         </Text>
  //         <Text fontSize="x-small">
  //           Durum: {props.item.maintenanceTransactionStatusDescription}
  //         </Text>
  //       </Box>
  //     </Flex>
  //     <Flex flexDir="column">
  //       <Button
  //         size="sm"
  //         colorScheme="blue"
  //         mb="2"
  //         onClick={() => {
  //           props.setDetail(props.item);
  //           props.current?.open();
  //         }}
  //       >
  //         Detay
  //       </Button>
  //       {[MaintenanceStatus.ready, MaintenanceStatus.late].includes(
  //         props.item.maintenanceTransactionStatus
  //       ) && (
  //         <Button
  //           colorScheme="teal"
  //           size="sm"
  //           onClick={() => {
  //             props.push(`/maintenance/do-maintenance/${props.item.id}`, {
  //               timelineItem: props.item,
  //             });
  //           }}
  //         >
  //           Bakım Yap
  //         </Button>
  //       )}
  //       {props.item.isMaintenanceComplete && (
  //         <Badge colorScheme="green">Bakım Yapıldı</Badge>
  //       )}
  //       {props.item.isMaintenanceComplete && (
  //         <Button
  //           colorScheme="pink"
  //           mt="2"
  //           size="sm"
  //           onClick={props.onClickReport}
  //         >
  //           Raporu Gör
  //         </Button>
  //       )}
  //     </Flex>
  //   </Flex>
  // );
}

const Timeline = forwardRef<any, any>((props, ref) => {
  const modalRef = useRef<ModalProps>(null);
  const scrollBarRef = useRef<any>(null);
  const { user, project } = useAuth();
  const formRef = useRef<ModalProps>(null);
  const [detail, setDetail] = useState<TimelineItem | null>(null);
  const [doMaintenance, statDoMain, dataDoMain] = Hooks.UpdateDoMaintenance();
  const [doCancel, statCancel] = Hooks.CancelMaintenance();
  const toast = useToast();
  const listRef = useRef<FixedSizeList>(null);

  const [forms, setForms] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [formUpdate, setFormUpdate] = useState<any>(null);
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    goToday: () => {
      getTodayTopPosition();
    },
    goUndone: () => {
      _goUndone();
    },
  }));

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        arrData.push(await toBase64(arr[index]));
      }

      return resolve(arrData);
    });
  }

  async function handleClickUpload() {
    setLoading(true);
    let base64List = await getBase64Photos(forms || []);
    await doMaintenance({
      inventoryID: (formUpdate as TimelineItemDetail).inventoryID,
      inventoryMaintenanceID: (formUpdate as any)?.id,
      images: (base64List as any[]).map((base64Str) => ({
        photo: base64Str,
        type: "formPhoto",
      })),
    });
    setLoading(false);
    setForms(null);
    setFormUpdate(null);
    toast({ title: "Form Yüklendi!" });
    formRef.current?.close();
    props.onNeedReload();
  }

  function getTodayIndex() {
    if (!!props.data) {
      let index = 0;
      let closeIndex = 0;
      let close = 0;
      while (index < props.data.length) {
        const item = props.data[index];
        const day = Moment(item.endDate).diff(Moment(), "days") + 0;
        if (index === 0) {
          closeIndex = index;
          close = day;
        } else {
          if (0 - day < close) {
            closeIndex = index;
            close = day;
          }
        }
        index++;
      }
      return closeIndex;
    }
  }

  function getTodayTopPosition() {
    let today = getTodayIndex();
    if (today || today === 0) {
      goItemPosition(today);
    }
  }

  function getUndoneIndex(data: any[]) {
    let undoneIndex = 0;
    if (!!data) {
      data.forEach((item: any, currIndex: number) => {
        if (
          [MaintenanceStatus.ready, MaintenanceStatus.late].includes(
            item.maintenanceTransactionStatus
          )
        ) {
          var undoneItem: any = data[undoneIndex];
          if (
            undoneItem.isMaintenanceComplete ||
            Moment(undoneItem.endDate).diff(item.endDate, "seconds") > 0
          ) {
            undoneIndex = currIndex;
          }
        }
      });
    }
    return undoneIndex;
  }

  function goItemPosition(index: number) {
    listRef.current?.scrollToItem(index);
  }

  function _goUndone() {
    const undoneIndex = getUndoneIndex(props.data);
    if (!!undoneIndex) {
      goItemPosition(undoneIndex);
    }
  }

  async function cancelMaintenance(item: any) {
    await doCancel({
      inventoryID: item.inventoryID,
      inventoryMaintenanceID: item.id,
    });
    props.onNeedReload();
  }

  useEffect(() => {
    if (!!props.data && props.data.length > 0) {
      _goUndone();
    }
  }, [props.data]);

  const Row = memo(({ index, style }: any) => {
    const item = props.data[index];
    const [doCompMaintenance, statDoCompletedMain, DataDoCompletedMain] =
      Hooks.CompletedMaintenanceById();
    return (
      <div style={style}>
        <Item
          current={modalRef.current}
          setDetail={setDetail}
          push={history.push}
          item={item}
          index={index}
          data={item}
          onClickCancel={() => {
            cancelMaintenance(item);
          }}
          onClickReport={async () => {
            const result = (await doCompMaintenance(
              project.id,
              item.id
            )) as unknown as any;
            if (!result?.data?.data) {
              return;
            }
            const row = result.data?.data;
            debugger;
            localStorage.removeItem("doc-report");
            localStorage.setItem("doc-report", JSON.stringify(row));
            window.open("/doc-report", "_blank");
          }}
        ></Item>
      </div>
    );
  });

  if (props.data.length === 0) {
    return (
      <Center height="300px">
        <Text>Bakım bulunamadı!</Text>
      </Center>
    );
  }

  return (
    <Fragment>
      <Flex flexDir="column" height="calc(100vh - 280px)">
        <ItemTitle />
        <Box flexGrow={1}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                ref={listRef}
                height={height}
                itemCount={props.data.length}
                itemSize={80}
                width={width}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      </Flex>
      <Modal
        ref={modalRef}
        onClose={() => setDetail(null)}
        noForm
        title={detail?.inventoryName}
      >
        {detail && <TimelineDetail data={detail} />}
      </Modal>
      <Modal
        ref={formRef}
        onClose={() => setDetail(null)}
        noForm
        title={`${formUpdate?.inventoryName} Form Yükle`}
      >
        {formUpdate && (
          <Box minH="250px">
            <Dropzone
              onDrop={(files) => {
                setForms(files);
              }}
            />
            <Flex justifyContent="flex-end" mt="5">
              <Button
                colorScheme="blue"
                onClick={handleClickUpload}
                isLoading={loading}
              >
                Form Yükle
              </Button>
            </Flex>
          </Box>
        )}
      </Modal>
    </Fragment>
  );
});

interface TimelineDetailProps {
  data: TimelineItem;
}
const TimelineDetail: FC<TimelineDetailProps> = (props) => {
  const [getMD, statMD, dataMD] = Hooks.MaintenanceTimelineDetail();
  useLayoutEffect(() => {
    getMD(props.data.id);
  }, []);

  return (
    <Box>
      {statMD !== "fulfilled" && <Spinner />}
      {statMD === "fulfilled" && (
        <Box>
          <Heading fontSize="md">Ekipman</Heading>
          <Divider my="2" />
          <Flex justifyContent="space-between" fontSize="xs" mb="2">
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  #ID:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).inventoryID}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Ekipman Adı:
                </Text>{" "}
                {props.data.inventoryName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Barkod:
                </Text>{" "}
                {props.data.barcode}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Asset No:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).assetNo}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Birim:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).unit}
              </Text>
            </Box>
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Tesis:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).campusName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Bina:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).buildName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Kat:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).floorName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Oda:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).roomName}
              </Text>
            </Box>
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Grup:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).groupName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Marka:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).brandName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Model:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).modelName}
              </Text>
            </Box>
          </Flex>

          <Heading fontSize="md" mt="5">
            Bakım
          </Heading>
          <Divider my="2" />
          <Flex justifyContent="space-between" fontSize="xs" mb="5">
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Tipi:
                </Text>{" "}
                {(dataMD?.data.data as TimelineItemDetail).maintenanceTypeName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Periyot:
                </Text>{" "}
                {
                  (dataMD?.data.data as TimelineItemDetail)
                    .maintenancePeriodName
                }
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Bakım Başlangıç Tarihi:
                </Text>{" "}
                {Moment(
                  (dataMD?.data.data as TimelineItemDetail).startDate
                ).format("DD.MM.YYYY")}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Planlı Bakım Tarihi:
                </Text>{" "}
                {Moment(
                  (dataMD?.data.data as TimelineItemDetail).endDate
                ).format("DD.MM.YYYY")}
              </Text>
            </Box>
          </Flex>
          {/* <Flex justifyContent="flex-end" mt="5" mb="3">
            <Button colorScheme="teal" size="sm">
              Bakım Yap
            </Button>
          </Flex> */}
        </Box>
      )}
    </Box>
  );
};

TimelineWidget.defaultProps = {};

export default TimelineWidget;
