import { Box, styled, useColorModeValue, chakra } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Card = (props: any) => {
  return <Box
    borderRadius="sm"
    padding="5"
    background={useColorModeValue("gray.50", "gray.900")}
    {...props}
  >
    {props.children}
  </Box>;
};

export default Card;
