import { Route } from "react-router-dom";
import { mainPaths } from "./path";

export const routes: any[] = [
  {
    route: Route,
    name: "Home",
    component: require("pages/Dashboard").default,
    path: mainPaths.Home,
    exact: true,
  },
  {
    route: Route,
    name: "Home Detail",
    component: require("pages/ProcessDetail").default,
    path: mainPaths.ProcessDetail,
    exact: true,
  },
  {
    route: Route,
    name: "Kampüs",
    component: require("pages/Campus").default,
    path: mainPaths.Campus,
    exact: true,
  },
  {
    route: Route,
    name: "Bina",
    component: require("pages/Build").default,
    path: mainPaths.Build,
    exact: true,
  },
  {
    route: Route,
    name: "Kat",
    component: require("pages/Floor").default,
    path: mainPaths.Floor,
    exact: true,
  },
  {
    route: Route,
    name: "Oda",
    component: require("pages/Room").default,
    path: mainPaths.Room,
    exact: true,
  },
  {
    route: Route,
    name: "Bakım Listesi",
    component: require("pages/Maintenance/AllList").default,
    path: "/maintenance/all",
    exact: true,
  },
  {
    route: Route,
    name: "Ekipman",
    component: require("pages/Inventory").default,
    path: mainPaths.Inventory,
    exact: true,
  },
  {
    route: Route,
    name: "Notlar",
    component: require("pages/Inventory/Notes").default,
    path: "/maintenance-result",
    exact: true,
  },
  {
    route: Route,
    name: "Ekipman",
    component: require("pages/Inventory/Non").default,
    path: "/maintenance-non",
    exact: true,
  },
  {
    route: Route,
    name: "Ekipman Oluştur",
    component: require("pages/Inventory/Create").default,
    path: mainPaths.InventoryCreate,
    exact: true,
  },
  {
    route: Route,
    name: "Ekipman Düzenle",
    component: require("pages/Inventory/Edit").default,
    path: `${mainPaths.InventoryEdit}/:id`,
    exact: true,
  },
  {
    route: Route,
    name: "Kullanıcı Oluştur",
    component: require("pages/User/Create").default,
    path: "/user/create",
    exact: true,
  },
  {
    route: Route,
    name: "Kullanıcılar",
    component: require("pages/User").default,
    path: "/user",
    exact: true,
  },
  {
    route: Route,
    name: "Şifre Değiştir",
    component: require("pages/ChangePassword").default,
    path: "/change-password",
    exact: true,
  },
  {
    route: Route,
    name: "Kullanıcı Düzenle",
    component: require("pages/User/Edit").default,
    path: "/user-edit/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Bakım Ekle",
    component: require("pages/Maintenance/Create").default,
    path: "/maintenance-create/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Bakım Listesi",
    component: require("pages/Maintenance").default,
    path: "/maintenance-list/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Soru Listesi",
    component: require("pages/Maintenance/Questions").default,
    path: "/maintenance-questions/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Bakım Düzenle",
    component: require("pages/Maintenance/Edit").default,
    path: "/maintenance-edit",
    exact: true,
  },
  {
    route: Route,
    name: "Periyodik Kontroller",
    component: require("pages/PeriodControls").default,
    path: "/periodic-controls/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Periyodik Kontrol Ekle",
    component: require("pages/PeriodControls/Create").default,
    path: "/periodic-controls-create/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Periyodik Kontrol Düzenle",
    component: require("pages/PeriodControls/Edit").default,
    path: "/periodic-controls-edit",
    exact: true,
  },
  {
    route: Route,
    name: "Kullanıcı Tipleri",
    component: require("pages/UserType").default,
    path: mainPaths.UserType,
    exact: true,
  },
  {
    route: Route,
    name: "Doküman Listesi",
    component: require("pages/Document").default,
    path: "/document",
    exact: true,
  },
  {
    route: Route,
    name: "Doküman Tanımla",
    component: require("pages/Document/Create").default,
    path: "/document-create",
    exact: true,
  },
  {
    route: Route,
    name: "Doküman Tanımla",
    component: require("pages/Document/AllDocument").default,
    path: "/all-document",
    exact: true,
  },
  {
    route: Route,
    name: "Doküman Düzenle",
    component: require("pages/Document/Edit").default,
    path: "/document-edit",
    exact: true,
  },
  {
    route: Route,
    name: "Kontrol Tanımla",
    component: require("pages/Control/Create").default,
    path: "/document/:id/control-create",
    exact: true,
  },
  {
    route: Route,
    name: "Kontrol Düzenle",
    component: require("pages/Control/Edit").default,
    path: "/document/control-edit",
    exact: true,
  },
  {
    route: Route,
    name: "Doküman Kontrolleri",
    component: require("pages/Control").default,
    path: "/document/:id/control",
    exact: true,
  },
  {
    route: Route,
    name: "Bilgilerimi Güncelle",
    component: require("pages/User/AuthInfo").default,
    path: "/my-account-edit",
    exact: true,
  },
  {
    route: Route,
    name: "Talep Olstur",
    component: require("pages/Demand/Create").default,
    path: "/demand/create",
    exact: true,
  },
  {
    route: Route,
    name: "Talep Duzenle",
    component: require("pages/Demand/Edit").default,
    path: "/demand/edit",
    exact: true,
  },
  {
    route: Route,
    name: "Talep İşlemleri",
    component: require("pages/Demand").default,
    path: "/demand",
    exact: true,
  },
  {
    route: Route,
    name: "Talep Dokuman",
    component: require("pages/Demand/Document").default,
    path: "/demand/document",
    exact: true,
  },
  {
    route: Route,
    name: "Tüm Talep Dokuman",
    component: require("pages/Demand/AllDocuments").default,
    path: "/all-demand-document",
    exact: true,
  },
  {
    route: Route,
    name: "Bakım Yap",
    component: require("pages/Maintenance/DoMaintenance").default,
    path: "/maintenance/do-maintenance/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Periyot Bakım Yap",
    component: require("pages/Maintenance/DoLegalMaintenance").default,
    path: "/maintenance/do-period-maintenance/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Periyot Bakım Yap",
    component: require("pages/Maintenance/DoDocumentMaintenance").default,
    path: "/maintenance/do-document-maintenance/:id",
    exact: true,
  },
  {
    route: Route,
    name: "Raporlar",
    component: require("pages/Reports").default,
    path: "/report",
    exact: true,
  },
  {
    route: Route,
    name: "Periyodik Kontrol Raporları",
    component: require("pages/Reports/LegalReport").default,
    path: "/period-report",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim İşlemleri",
    component: require("pages/ControlTask").default,
    path: "/control",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim İşlemi Oluştur",
    component: require("pages/ControlTask/Create").default,
    path: "/control-create",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Düzenle",
    component: require("pages/ControlTask/Edit").default,
    path: "/control-edit",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Raporlar",
    component: require("pages/Reports/Completed").default,
    path: "/report/completed",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Raporlar",
    component: require("pages/Reports/DemandReport").default,
    path: "/demand-report",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Raporlar",
    component: require("pages/Reports/LegalReport/Completed").default,
    path: "/report/completed-period",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Talepler",
    component: require("pages/Reports/DemandReport/Completed").default,
    path: "/report/completed-demand",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Talepler",
    component: require("pages/Reports/DemandReport/InCompleted").default,
    path: "/report/incompleted-demand",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Raporlar",
    component: require("pages/Reports/LegalReport/Incompleted").default,
    path: "/report/incompleted-period",
    exact: true,
  },
  {
    route: Route,
    name: "Gerçekleşen Raporlar",
    component: require("pages/Reports/LegalReport/Wait").default,
    path: "/report/wait-period",
    exact: true,
  },
  {
    route: Route,
    name: "Zamanında Gerçekleşmeyen Raporlar",
    component: require("pages/Reports/InCompleted").default,
    path: "/report/incompleted",
    exact: true,
  },
  {
    route: Route,
    name: "Zamanında Gerçekleşmeyen Raporlar",
    component: require("pages/Reports/Waiting").default,
    path: "/report/waiting-form",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/DocReport").default,
    path: "/doc-report",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/LegalReport/DocReport").default,
    path: "/doc-report-legal",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/ControlTaskReport/DocReport").default,
    path: "/doc-report-control-task",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/DocumentReport/DocReport").default,
    path: "/doc-report-document",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/DocumentReport").default,
    path: "/report-document",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/DocumentReport/Completed").default,
    path: "/report-document-completed",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/DocumentReport/Incompleted").default,
    path: "/report-document-incompleted",
    exact: true,
  },
  {
    route: Route,
    name: "Rapor",
    component: require("pages/Reports/DocumentReport/Wait").default,
    path: "/report-document-wait",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Soru Formları",
    component: require("pages/ControlTask/Question/FormList").default,
    path: "/control-question-form",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Grup Soruları",
    component: require("pages/ControlTask/Question/QuestionList").default,
    path: "/control-questions",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Görevleri",
    component: require("pages/ControlTask/Task").default,
    path: "/control-tasks",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Görevi Gerçekleştir",
    component: require("pages/Maintenance/DoControl").default,
    path: "/do-control-task",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Raporları",
    component: require("pages/Reports/ControlTaskReport").default,
    path: "/report-control-task",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Raporları",
    component: require("pages/Reports/ControlTaskReport/Completed").default,
    path: "/report-control-task-completed",
    exact: true,
  },
  {
    route: Route,
    name: "Denetim Raporları",
    component: require("pages/Reports/ControlTaskReport/Incompleted").default,
    path: "/report-control-task-incompleted",
    exact: true,
  },
  {
    route: Route,
    name: "Tüm Görevler",
    component: require("pages/ControlTask/AllTask").default,
    path: "/all-task",
    exact: true,
  },
  {
    route: Route,
    name: "Sayaç Listesi",
    component: require("pages/Counter").default,
    path: "/counter",
    exact: true,
  },
  {
    route: Route,
    name: "Sayaç Tanımla",
    component: require("pages/Counter/Create").default,
    path: "/counter/create",
    exact: true,
  },
  {
    route: Route,
    name: "Sayaç Bakımları",
    component: require("pages/Counter/Maintenance").default,
    path: "/counter/:counterId/maintenance",
    exact: true,
  },
  {
    route: Route,
    name: "Sayaç Bakımı Oluştur",
    component: require("pages/Counter/Maintenance/Create").default,
    path: "/counter/:counterId/maintenance-create",
    exact: true,
  },
  {
    route: Route,
    name: "Sayaç Bakımı Yap",
    component: require("pages/Counter/DoMaintenance").default,
    path: "/counter/do-maintenance/:maintenanceId/:taskId",
    exact: true,
  },
  {
    route: Route,
    name: "Bakım Çizelgesi",
    component: require("pages/Timeline/TimelinePage").default,
    path: "/timeline",
    exact: true,
  },
  {
    route: Route,
    name: "Günlük Notlar",
    component: require("pages/Notes/Notes").default,
    path: "/note",
    exact: true,
  },
  {
    route: Route,
    name: "Tüm Notlar",
    component: require("pages/Notes/AllNotes").default,
    path: "/all-note",
    exact: true,
  },
  {
    route: Route,
    name: "Bulunamadı",
    component: require("pages/Errors/NotFound").default,
    path: "",
    exact: true,
  },
];

export const authRoutes: any[] = [
  {
    route: Route,
    name: "Login",
    component: require("pages/Login").default,
  },
];
